import { LoginComponent } from '@percihealth/react-auth';

export default function ExpertLoginPage() {
  return (
    <LoginComponent
      awsCognitoEmailSettings={{
        region: process.env.REACT_APP_COGNITO_REGION!,
        userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID!,
        userPoolWebClientId: process.env.REACT_APP_COGNITO_WEB_CLIENT_ID!,
      }}
    />
  );
}
