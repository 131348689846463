import { CareDocumentOvercomeChallenge } from '@packages/core-shared';
import { AlertError, Button, InputText, TextArea } from '@percihealth/react';
import { useState } from 'react';
import RecommendationList from './RecommendationList';
import styles from './OvercomeChallenges.module.css';

export default function OvercomeChallenges({
  values,
  onChange,
  editProgress,
}: {
  values: CareDocumentOvercomeChallenge[];
  onChange: (arr: CareDocumentOvercomeChallenge[]) => void;
  editProgress?: boolean;
}) {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [newChallenge, setNewChallenge] = useState('');

  const add = () => {
    if (!newChallenge || newChallenge.trim().length === 0) {
      setErrorMessage('challenge should not be empty');
      return;
    } else if (values.some((res) => res.challenge === newChallenge)) {
      setErrorMessage('challenge already exists');
      return;
    }

    setErrorMessage(null);
    setNewChallenge('');
    onChange([...values, { challenge: newChallenge, recommendations: [] }]);
  };

  const remove = (toRemove: CareDocumentOvercomeChallenge) => {
    onChange(values.filter((res) => res !== toRemove));
  };

  return (
    <div className="full-width">
      <div className={styles['challenges-container']}>
        {values.map((v) => (
          <div key={v.challenge}>
            <div className={styles.challenge}>
              <InputText label="Challenge" readOnly value={v.challenge} />
              <Button
                type="button"
                className={styles.remove}
                onClick={() => remove(v)}
              >
                Delete challenge
              </Button>
            </div>
            <RecommendationList
              recommendations={v.recommendations ?? []}
              onChange={(recs) => {
                onChange(
                  values.map((vv) => {
                    if (vv.challenge === v.challenge) {
                      return { ...vv, recommendations: recs };
                    }
                    return vv;
                  }),
                );
              }}
            />

            {editProgress && (
              <TextArea
                label="Progress"
                value={v.progress ?? ''}
                onChange={(e) => {
                  onChange(
                    values.map((vv) => {
                      if (vv.challenge === v.challenge) {
                        return { ...vv, progress: e.target.value };
                      }
                      return vv;
                    }),
                  );
                }}
              />
            )}

            <hr />
          </div>
        ))}
      </div>
      <div>
        <div
          className={styles['add-container']}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.stopPropagation();
              e.preventDefault();
              add();
            }
          }}
        >
          <InputText
            label="New challenge"
            value={newChallenge}
            onChange={(e) => setNewChallenge(e.target.value)}
          />
          <Button type="button" className={styles.add} onClick={add}>
            Add challenge
          </Button>
        </div>
        {errorMessage && <AlertError>{errorMessage}</AlertError>}
      </div>
    </div>
  );
}
