import { AlertError, Button, InputText } from '@percihealth/react';
import { useState } from 'react';
import styles from './DocumentColumnList.module.css';

export default function DocumentColumnList({
  rows,
  onChange,
}: {
  rows: string[];
  onChange: (arr: string[]) => void;
}) {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [value, setValue] = useState('');

  const add = () => {
    if (!value || value.trim().length === 0) {
      setErrorMessage('Item should not be empty');
      return;
    } else if (rows.some((res) => res === value)) {
      setErrorMessage('Item already exists');
      return;
    }

    setErrorMessage(null);
    setValue('');
    onChange([...rows, value]);
  };

  const remove = (toRemove: string) => {
    onChange(rows.filter((res) => res !== toRemove));
  };

  return (
    <div>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Impact</th>
            <th style={{ width: '220px' }}>Delete</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row) => (
            <tr key={row}>
              <td>{row}</td>
              <td>
                <a onClick={() => remove(row)}>Delete</a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div
        className={styles['add-container']}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.stopPropagation();
            e.preventDefault();
            add();
          }
        }}
      >
        <InputText value={value} onChange={(e) => setValue(e.target.value)} />
        <Button type="button" className={styles.add} onClick={add}>
          Add
        </Button>
      </div>
      {errorMessage && <AlertError>{errorMessage}</AlertError>}
    </div>
  );
}
