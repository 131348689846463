import { CareDocumentOutcome } from '@packages/core-shared';
import { FormikInputText } from '@percihealth/react';
import styles from './ClinicalExperienceAndOutcomesItem.module.css';

export function ClinicalExperienceAndOutcomesItem({
  name,
  concern,
  outcome,
  formikId,
  disabled,
  disabledInitialAssessment,
  disabledFollowUp,
}: {
  name: string;
  concern?: string;
  formikId: string;
  disabled?: boolean;
  disabledInitialAssessment?: boolean;
  disabledFollowUp?: boolean;
} & { outcome: CareDocumentOutcome }) {
  return (
    <div className="full-width">
      <div className={styles.container}>
        <div className={styles['name-container']}>
          <div className={styles.name}>{name}</div>
          {concern && <div className={styles.concern}>{concern}</div>}
        </div>
        <FormikInputText
          label="Initial assessment"
          name={`${formikId}.initial`}
          value={outcome.initial ?? ''}
          disabled={disabled || disabledInitialAssessment}
        />
        <FormikInputText
          label="Follow up score"
          name={`${formikId}.followUp`}
          value={outcome.followUp ?? ''}
          disabled={disabled || disabledFollowUp}
        />
        <FormikInputText
          label="Change"
          name={`${formikId}.change`}
          value={outcome.change ?? ''}
          disabled={disabled}
        />
      </div>
    </div>
  );
}
