import { EbpTypeformType, WorkFocusForm } from '@packages/core-shared';
import { and, collection, orderBy, query, where } from 'firebase/firestore';
import { useMemo } from 'react';
import { useSubscribeFirestoreList } from '../../hooks/useSubscribeFirestoreList';
import { useFirebase } from '../../context';

export const useLoadSafeGuardableForms = ({
  patientEmail,
  formType,
}: {
  patientEmail?: string;
  formType: EbpTypeformType;
}) => {
  const { firestore } = useFirebase();

  const queryResult = useMemo(() => {
    let q = query(
      collection(firestore, 'forms'),
      and(
        where('hidden_email', '==', patientEmail ?? '__NON_EXISTING_EMAIL__'),
        where('formType', '==', formType),
      ),
      orderBy('createdAt', 'desc'),
    );
    return q;
  }, [firestore, patientEmail, formType]);

  const loadFirestoreList =
    useSubscribeFirestoreList<WorkFocusForm>(queryResult);
  return loadFirestoreList;
};
