import { CareDocument, CareDocumentType } from '@packages/core-shared';
import { collection, orderBy, query, where } from 'firebase/firestore';
import { useMemo } from 'react';
import { useSubscribeFirestoreList } from '../../hooks/useSubscribeFirestoreList';
import { useFirebase } from '../../context';

export function useSubscribeCareDocumentsByCaseId<TRes extends CareDocument>({
  caseId,
  type,
  map,
}: {
  caseId: string;
  type?: CareDocumentType;
  map?: (doc: CareDocument) => TRes;
}) {
  const { firestore } = useFirebase();

  const queryResult = useMemo(() => {
    let q = query(
      collection(firestore, 'documents'),
      where('caseId', '==', caseId),
    );

    if (type) {
      q = query(q, where('type', '==', type));
    }

    q = query(q, orderBy('createdAt', 'desc'));

    return q;
  }, [firestore, caseId, type]);

  const loadFirestoreList = useSubscribeFirestoreList<TRes>(
    queryResult,
    500,
    map,
  );

  return loadFirestoreList;
}
