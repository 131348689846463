// import { DropdownSearch } from '@percihealth/react';
// import { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './WorkFocusedFormLink.module.scss';
import { CancerStatus } from '@packages/core-shared';

// enum Options {
//   Wellbeing = 'Well-being',
//   WorkReadiness = 'Work readiness',
//   WellbeingAndWorkReadiness = 'Well-being and work readiness',
// }

// const options = [
//   Options.Wellbeing,
//   Options.WorkReadiness,
//   Options.WellbeingAndWorkReadiness,
// ].map((o) => ({
//   value: o,
//   name: o,
// }));

interface Props {
  email?: string;
  fullName?: string;
  cancerStatus?: CancerStatus;
}

export const WorkFocusedFormLink = ({ email, fullName }: Props) => {
  // const [option, setOption] = useState(Options.Wellbeing);

  if (!email || !fullName) {
    return <></>;
  }

  const formId = process.env.REACT_APP_WORK_FOCUSED_FORM_ID;
  // const s1 =
  //   option === Options.Wellbeing ||
  //   option === Options.WellbeingAndWorkReadiness;
  // const s2 =
  //   option === Options.WorkReadiness ||
  //   option === Options.WellbeingAndWorkReadiness;

  // const link = `https://percihealth.typeform.com/to/${formId}#email=${encodeURIComponent(
  //   email,
  // )}&name=${encodeURIComponent(fullName)}${s1 ? '&s1=true' : ''}${
  //   s2 ? '&s2=true' : ''
  // }`;

  const link = `https://percihealth.typeform.com/to/${formId}#email=${encodeURIComponent(
    email,
  )}`;

  return (
    <div className={styles.container}>
      {/* <DropdownSearch
        width="auto"
        multiple={false}
        value={option}
        onChange={(v) => setOption(v as Options)}
        options={options}
      /> */}
      <Link to={link} target="_blank">
        {`Add "VRP PROMs" form`}
      </Link>
    </div>
  );
};
