// Adds <br/> for new lines,
// converts '-' at the beginning of a line to a bullet point
export default function richTextHtml(text?: string) {
  if (!text) {
    return text;
  }

  return text.split('\n').map((line, idx) => {
    if (line.startsWith('- ')) {
      return (
        <span key={idx} style={{ marginLeft: '8px', display: 'inline-block' }}>
          <span style={{ marginLeft: '0.5rem' }}>∙</span> {line.substring(1)}
          <br />
        </span>
      );
    }

    return (
      <span key={idx}>
        {line}
        <br />
      </span>
    );
  });
}
