import { useMemo } from 'react';
import { useSubscribePatientAppointments } from './useSubscribePatientAppointments';

export const useSubscribePatientAppointmentsWithTranscriptions = ({
  patientEmail,
}: {
  patientEmail?: string;
}) => {
  const appointmentsSubscriber = useSubscribePatientAppointments({
    patientEmail,
  });

  const result = useMemo(() => {
    const filteredRecords = appointmentsSubscriber.records.filter(
      (app) => app.transcriptions && app.transcriptions.length > 0,
    );

    return {
      errorMessage: appointmentsSubscriber.errorMessage,
      loading: appointmentsSubscriber.loading,
      records: filteredRecords,
    };
  }, [
    appointmentsSubscriber.errorMessage,
    appointmentsSubscriber.loading,
    appointmentsSubscriber.records,
  ]);

  return result;
};
