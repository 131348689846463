import { ApiRepository } from '../../helpers/ApiRepository';

export class EmailsApiRepository extends ApiRepository {
  async resend(data: { caseId: string; documentId: string; template: string }) {
    const token = await this.getToken();
    if (!token) {
      throw new Error('Unauthorized');
    }

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/emails/resend`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      },
    );

    if (!response.ok) {
      throw new Error('Error resending the email');
    }
  }

  async syncStatus(emailId: string) {
    const token = await this.getToken();
    if (!token) {
      throw new Error('Unauthorized');
    }

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/emails/${emailId}/sync`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({}),
      },
    );

    if (!response.ok) {
      throw new Error('Error synchronizing statuses');
    }
  }
}
