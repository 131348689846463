import { dateTimeToLocalDateTimeString } from '@packages/core-shared';
import { AlertError, Button } from '@percihealth/react';
import { useCallback, useState } from 'react';
import { ReferralsApiRepository } from '../../../../../../api';
import { ReferralDownloadFileLink } from '../ReferralDownloadFileLink';

export default function ReferralDocumentsRow({
  referralsApiRepository,
  referralId,
  referralDoc,
  rowNumber,
  readonly,
}: {
  referralsApiRepository: ReferralsApiRepository;
  referralId: string;
  rowNumber: number;
  referralDoc: {
    path?: string;
    name: string;
    uploadedToHealee?: { at: Date; by?: string | null };
  };
  readonly: boolean;
}) {
  const [isUploading, setIsUploading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const uploadToHealee = useCallback(async () => {
    try {
      setIsUploading(true);
      setErrorMessage(null);
      const resp = await referralsApiRepository.uploadReferralFormToHealee({
        referralId,
        fileName: referralDoc.name,
      });

      if (!resp.ok) {
        try {
          const json = await resp.json();
          setErrorMessage(json.errors?.join(', ') ?? 'Internal error');
        } catch {
          setErrorMessage(resp.statusText);
        }
      }
    } catch (err) {
      console.log(err);
      setErrorMessage((err as Error).message);
    } finally {
      setIsUploading(false);
    }
  }, [referralId, referralDoc.name, referralsApiRepository]);

  return (
    <tr>
      <td>{rowNumber}</td>
      <td>
        <ReferralDownloadFileLink
          key={`${referralDoc.path}/${referralDoc.name}`}
          referralId={referralId}
          fileName={referralDoc.name}
          filePath={referralDoc.path}
        />
      </td>
      <td>
        {referralDoc.uploadedToHealee ? (
          <div>
            Uploaded at
            <br />
            {dateTimeToLocalDateTimeString(referralDoc.uploadedToHealee.at)}
          </div>
        ) : (
          <Button
            disabled={readonly}
            submitting={isUploading}
            onClick={uploadToHealee}
          >
            Share&nbsp;with&nbsp;MDT
          </Button>
        )}
        {errorMessage && (
          <div>
            <AlertError>{errorMessage}</AlertError>
          </div>
        )}
      </td>
    </tr>
  );
}
