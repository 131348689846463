import { useContext, useEffect } from 'react';
import { EventContext } from './EventContext';

export const useEvent = (event: any, callback: any) => {
  const [subscribe, unsubscribe, _dispatch] = useContext(EventContext);

  useEffect(() => {
    subscribe(event, callback);

    return () => unsubscribe(event, callback);
  }, [subscribe, unsubscribe, event, callback]);
};
