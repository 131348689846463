import { useEventDispatch } from '@packages/web-shared/hooks/eventEmitter/useEventDispatch';
import { useCallback } from 'react';
import {
  EbpMedicalHistoryField,
  getColorForField,
} from './EbpMedicalHistoryField';
import { MedicalHistoryLocateEvents } from './MedicalHistoryLocateEvents';
import SyncIcon from './SyncIcon';

export default function FieldWithLocator({
  children,
  type,
  docPath,
}: {
  children: React.ReactNode;
  type: EbpMedicalHistoryField;
  docPath?: string;
}) {
  const dispatchEvent = useEventDispatch();
  const locate = useCallback(() => {
    dispatchEvent(MedicalHistoryLocateEvents.ValidationLocateDocumentEvent, {
      field: type,
      docPath,
    });
  }, [dispatchEvent]);

  return (
    <div
      style={{
        minWidth: '100%',
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        paddingRight: '0',
      }}
    >
      {children}
      <SyncIcon
        width={36}
        style={{
          color: getColorForField(type),
          alignSelf: 'end',
          margin: '0 6px 7px 6px',
          cursor: 'pointer',
        }}
        onClick={locate}
      />
    </div>
  );
}
