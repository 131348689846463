import styles from './PcpConcern.module.css';
import { default as CrossSvg } from './cross.svg';

interface Props {
  concern: string;
  onDelete: () => void;
}

export default function PcpConcern({ concern, onDelete }: Props) {
  return (
    <div className={styles.container}>
      {concern}
      <img className={styles.delete} src={CrossSvg} onClick={onDelete} />
    </div>
  );
}
