import { Button, Modal } from '@percihealth/react';
import { useNavigate } from 'react-router-dom';

interface Props {
  caseId: string | null;
  open: boolean;
  onClose: () => void;
}

export const PdfGeneratedModal = ({ caseId, open, onClose }: Props) => {
  const navigate = useNavigate();
  return (
    <Modal open={open} onClose={() => onClose()} center>
      <h3>PDF generated</h3>
      <p>PDF document was successfully generated.</p>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          marginBottom: '16px',
        }}
      >
        <Button
          onClick={() => {
            onClose();
            navigate(`/cases/${caseId}`);
          }}
        >
          Go back to case
        </Button>
      </div>
    </Modal>
  );
};
