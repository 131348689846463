import { limit } from '@firebase/firestore';
import { EbpTypeformType, IObject } from '@packages/core-shared';
import { and, collection, orderBy, query, where } from 'firebase/firestore';
import { useEffect, useMemo, useState } from 'react';
import { useLoadLimitedFirestoreList } from '../../hooks/useLoadLimitedFirestoreList';
import { useFirebase } from '../../context';

export function useLoadFirstForm<
  TDoc extends IObject.Timestamped<unknown>,
  TResult extends TDoc = TDoc,
>({
  patientEmail,
  formType,
}: {
  patientEmail: string | null;
  formType: EbpTypeformType;
}) {
  const { firestore } = useFirebase();

  const [record, setRecord] = useState<TResult | null>(null);

  const queryResult = useMemo(() => {
    let q = query(
      collection(firestore, 'forms'),
      and(
        where('hidden_email', '==', patientEmail ?? '__NON_EXISTING_EMAIL__'),
        where('formType', '==', formType),
      ),
      orderBy('createdAt', 'asc'),
      limit(1),
    );
    return q;
  }, [firestore, patientEmail]);

  const formsLoader = useLoadLimitedFirestoreList<TDoc, TResult>(
    queryResult,
    1,
  );

  useEffect(() => {
    if (formsLoader.records.length > 0) {
      setRecord(formsLoader.records[0]);
    }
  }, [formsLoader.records]);

  const result = useMemo(
    () => ({
      record: record,
      loading: formsLoader.loading,
      errorMessage: formsLoader.errorMessage,
    }),
    [record, formsLoader.errorMessage, formsLoader.loading],
  );
  return result;
}
