import { AlertError, Button, Checkbox, FormLabel } from '@percihealth/react';
import SafeguardCheckHistory from '../../../../../SafeguardCheckHistory';
import {
  EbpTypeformType,
  SafeGuardableForm,
  WorkFocusForm,
  WorkFocusedPlanFormFieldRef,
  dateTimeToLocalDateTimeString,
} from '@packages/core-shared';
import { useMemo, useState } from 'react';
import { FormsApiRepository } from '../../../../../../../api';
import { getOrderedFields } from '../../getOrderedFields';
import { getCoercedFormTitle } from '../../getCoercedFormTitle';
import styles from './SafeGuardableFormComponent.module.scss';
import { useFirebase } from '../../../../../../../context';

interface Props {
  form: SafeGuardableForm;
  allowEditSafeguardCheck: boolean;
}

export default function SafeGuardableFormComponent({
  form,
  allowEditSafeguardCheck,
}: Props) {
  const { auth } = useFirebase();

  const [updatingSafeguard, setUpdatingSafeguard] = useState(false);
  const [updateSafeguardErrorMessage, setUpdateSafeguardErrorMessage] =
    useState<string | null>(null);

  const formsApiRepository = useMemo(
    () => new FormsApiRepository(auth),
    [auth],
  );

  const updateSafeguard = async (
    form: SafeGuardableForm,
    safeguardCheck: boolean,
  ) => {
    try {
      setUpdatingSafeguard(true);
      setUpdateSafeguardErrorMessage(null);
      await formsApiRepository.updateSafeguard(form.id, safeguardCheck);
      // update the UI
      form.safeguardCheck = safeguardCheck;
    } catch (err) {
      console.error(err);
      setUpdateSafeguardErrorMessage(
        err instanceof Error ? err.message : JSON.stringify(err),
      );
    } finally {
      setUpdatingSafeguard(false);
    }
  };

  const workFocusedForm = useMemo(
    () =>
      form.formType === EbpTypeformType.WorkFocused
        ? (form as WorkFocusForm)
        : undefined,
    [form],
  );

  const orderedFields = getOrderedFields(form);

  const fatigueField = orderedFields.find(
    (field) => field.ref === WorkFocusedPlanFormFieldRef.Fatigue,
  );

  return (
    <div className={styles.container}>
      {updateSafeguardErrorMessage && (
        <AlertError>{updateSafeguardErrorMessage}</AlertError>
      )}
      <div>
        <FormLabel bold>Safeguarding check</FormLabel>
        <div className={styles.safeguard}>
          <Checkbox
            text="Checked"
            className={styles.checkmark}
            disabled
            value=""
            checked={form.safeguardCheck}
          />
          <Button
            onClick={() => {
              updateSafeguard(form, !form.safeguardCheck);
            }}
            submitting={updatingSafeguard}
            disabled={!allowEditSafeguardCheck}
          >
            {form.safeguardCheck ? 'Mark as unchecked' : 'Mark as checked'}
          </Button>
        </div>
        <SafeguardCheckHistory formId={form.id} />
      </div>
      <hr />
      <div>
        <FormLabel bold>Time to complete</FormLabel>
        <span>{form.time_to_complete}</span>
      </div>
      <div>
        <FormLabel bold>Created</FormLabel>
        <span>{dateTimeToLocalDateTimeString(form.createdAt)}</span>
      </div>
      {!workFocusedForm && (
        <div className="full-width">
          <div>
            <FormLabel bold>Typeform responses</FormLabel>
            <ol className={styles['latest-form']}>
              {orderedFields.map((field) => (
                <li key={field.ref} className={styles.qa}>
                  {getCoercedFormTitle(field.title, form)}
                  <p className={styles.answer}>{field.value}</p>
                </li>
              ))}
            </ol>
          </div>
        </div>
      )}
      {workFocusedForm && (
        <>
          <div>
            <FormLabel bold>Scores</FormLabel>
            <p>
              RTW-SE: <b>{workFocusedForm.var_rtw_se}</b>
            </p>
            <p>
              Fatigue: <b>{fatigueField?.value ?? '-'}</b>
            </p>
          </div>
          <div className="full-width">
            <div>
              <FormLabel bold>Typeform responses</FormLabel>

              <FormLabel bold>RTW-SE</FormLabel>
              <ol className={styles['latest-form']}>
                {orderedFields
                  .filter((field) => field.ref.includes('rtw-se'))
                  .map((field) => (
                    <li key={field.ref} className={styles.qa}>
                      {getCoercedFormTitle(field.title, form)}
                      <p className={styles.answer}>{field.value}</p>
                    </li>
                  ))}
              </ol>

              <FormLabel bold>Fatigue</FormLabel>
              <ol className={styles['latest-form']}>
                {fatigueField ? (
                  <li key={fatigueField.ref} className={styles.qa}>
                    {getCoercedFormTitle(fatigueField.title, form)}
                    <p className={styles.answer}>{fatigueField.value}</p>
                  </li>
                ) : (
                  '-'
                )}
              </ol>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
