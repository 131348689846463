import { CareDocumentCheckableItem } from '@packages/core-shared';
import { Checkbox, InputText } from '@percihealth/react';
import styles from './DocumentCheckableControl.module.css';

interface Props extends CareDocumentCheckableItem {
  name: string;
  placeholder?: string;
  onChange: (v: CareDocumentCheckableItem) => void;
}

export const DocumentCheckableControl = ({
  checked,
  name,
  value,
  placeholder,
  onChange,
}: Props) => {
  return (
    <div className={styles.container}>
      <Checkbox
        className={styles.checkbox}
        text={name}
        checked={checked}
        value={name}
        onChange={(e) => {
          onChange({
            checked: e.target.checked,
            // clear input if unchecked
            value: e.target.checked ? value : '',
          });
        }}
      />
      <InputText
        value={value ?? ''}
        placeholder={placeholder}
        disabled={!checked}
        onChange={(e) => {
          onChange({
            checked,
            value: e.target.value,
          });
        }}
      />
    </div>
  );
};
