import { CareDocumentTreatmentDetails } from '@packages/core-shared';
import { FormFieldset, FormikInputText } from '@percihealth/react';

export default function DocumentTreatmentDetailsList({
  formik,
}: {
  formik: { values: { treatmentDetails: CareDocumentTreatmentDetails } };
}) {
  return (
    <FormFieldset title="Treatment details">
      <FormikInputText
        id="treatmentDetails.treatment"
        name="treatmentDetails.treatment"
        required
        label="Treatment to date"
        className="full-width"
        placeholder="Example: chemotherapy, immunotherapy"
        value={formik.values.treatmentDetails.treatment}
      />
      <FormikInputText
        id="treatmentDetails.plan"
        name="treatmentDetails.plan"
        required
        label="Ongoing treatment plan"
        placeholder="Example: Surveillance scans and bloods every 3 months for 2 years"
        className="full-width"
        value={formik.values.treatmentDetails.plan}
      />
      <FormikInputText
        id="treatmentDetails.expectedEndDate"
        name="treatmentDetails.expectedEndDate"
        required
        label="Expected treatment end date"
        placeholder="Example: Active anti-cancer treatment completed August 2023"
        className="full-width"
        value={formik.values.treatmentDetails.expectedEndDate}
      />
      <FormikInputText
        id="treatmentDetails.other"
        name="treatmentDetails.other"
        required
        label="Other medical relevant history"
        className="full-width"
        value={formik.values.treatmentDetails.other}
      />
    </FormFieldset>
  );
}
