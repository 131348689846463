import { InternalUser } from '@packages/core-shared';
import { collection, orderBy, query, where } from 'firebase/firestore';
import { useLoadFirestoreList } from '../../hooks/useLoadFirestoreList';
import { useFirebase } from '../../context';
import { useMemo } from 'react';

export const useLoadUsers = (filter?: { expert: boolean }) => {
  const { firestore } = useFirebase();

  const q = useMemo(() => {
    let q = query(collection(firestore, 'users'));

    if (filter?.expert) {
      q = query(q, where('expert', '==', true));
    }

    q = query(q, orderBy('createdAt', 'desc'));

    return q;
  }, [firestore, filter]);

  const loadFirestoreList = useLoadFirestoreList<InternalUser>(q);
  return loadFirestoreList;
};
