import {
  EndOfCareProfessionalSeen,
  dateToLondonNumericDateString,
} from '@packages/core-shared';
import styles from './ProfessionalsSeen.module.css';

export default function ProfessionalsSeen({
  values,
  onChange,
}: {
  values: EndOfCareProfessionalSeen[];
  onChange: (arr: EndOfCareProfessionalSeen[]) => void;
}) {
  const remove = (index: number) => {
    onChange(values.filter((_, idx) => idx !== index));
  };

  return (
    <div className="full-width">
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Support type</th>
            <th>Session date</th>
            <th>Status</th>
            <th>Progress update</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {values.map((ps, index) => (
            <tr key={ps.service.id}>
              <td>{ps.service.name}</td>
              <td>
                {ps.appointments.map((app) => (
                  <div key={app.id} className={styles.appointment}>
                    {dateToLondonNumericDateString(app.date)}
                  </div>
                ))}
              </td>
              <td>
                {ps.appointments.map((app) => (
                  <div key={app.id} className={styles.appointment}>
                    {app.professionalName}
                  </div>
                ))}
              </td>
              <td>
                <a onClick={() => remove(index)}>Delete</a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
