import styles from './EmailShareActivity.module.scss';
import { AlertError, classList, Spinner } from '@percihealth/react';
import { useLoadCaseEmails } from '../../../api';
import {
  displayEbpDocumentType,
  CareDocument,
  dateTimeToLocalDateTimeString,
} from '@packages/core-shared';
import PauboxStatusCell from './PauboxStatusCell';
import PauboxResendCell from './PauboxResendCell';
import { useEffect, useMemo, useState } from 'react';
import { EmailEvent } from '@packages/core-shared';

interface Props {
  caseId?: string;
  documentsLoader: {
    loading: boolean;
    errorMessage: string | null;
    records: CareDocument[];
  };
  readonly: boolean;
}

export default function EmailShareActivity({
  caseId,
  documentsLoader,
  readonly,
}: Props) {
  if (!caseId) {
    return <></>;
  }

  const emailsLoader = useLoadCaseEmails(caseId);

  const [documentsMap, setDocumentsMap] = useState<
    Record<string, CareDocument>
  >({});

  useEffect(() => {
    setDocumentsMap(
      documentsLoader.records.reduce(
        (acc, curr) => {
          acc[curr.id] = curr;
          return acc;
        },
        {} as Record<string, CareDocument>,
      ),
    );
  }, [documentsLoader.records]);

  const records = useMemo(() => {
    return emailsLoader.records
      .map((email) => ({
        email,
        document: documentsMap[email.documentId ?? ''],
      }))
      .filter((r) => !!r.document);
  }, [documentsMap, emailsLoader.records]);

  return (
    <div className={classList(styles.container, 'full-width')}>
      {(emailsLoader.loading || documentsLoader.loading) && <Spinner />}
      {emailsLoader.errorMessage && (
        <AlertError>{emailsLoader.errorMessage}</AlertError>
      )}
      {documentsLoader.errorMessage && (
        <AlertError>{documentsLoader.errorMessage}</AlertError>
      )}
      {!emailsLoader.loading && !documentsLoader.loading && (
        <table style={{ width: '100%' }}>
          <thead>
            <tr>
              <th>Type</th>
              <th>Activity</th>
              <th>Status</th>
              <th>Events</th>
              <th>Resend</th>
            </tr>
          </thead>
          <tbody>
            {records.map((rec) => (
              <tr key={rec.email.id}>
                <td>
                  <span>{displayEbpDocumentType(rec.document.type)}</span>
                </td>
                <td>
                  <span>{`${rec.document.patient?.fullName}, ${rec.document.createdAt}`}</span>
                </td>
                <td>
                  <PauboxStatusCell email={rec.email} disabled={readonly} />
                </td>
                <td>
                  <ul>
                    {rec.email.events?.map((event) => (
                      <li key={(event as EmailEvent).id}>
                        {dateTimeToLocalDateTimeString(
                          (event as EmailEvent).createdAt,
                        )}{' '}
                        - {event.RecordType}
                      </li>
                    ))}
                  </ul>
                </td>
                <td>
                  <PauboxResendCell
                    caseId={caseId}
                    documentId={rec.document.id}
                    template={rec.email.template}
                    disabled={readonly}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}
