import { Organization } from '@packages/core-shared';
import { collection, query } from 'firebase/firestore';
import { useMemo } from 'react';
import { useLoadFirestoreList } from '../../hooks/useLoadFirestoreList';
import { useFirebase } from '../../context';

export const useLoadOrganizations = () => {
  const { firestore } = useFirebase();
  const queryResult = useMemo(
    () => query(collection(firestore, 'organizations')),
    [firestore],
  );

  const loadFirestoreList = useLoadFirestoreList<Organization>(queryResult);
  return loadFirestoreList;
};
