import { Aws } from '@packages/core-shared';
import { useEventDispatch } from '@packages/web-shared/hooks/eventEmitter/useEventDispatch';
import { Spinner } from '@percihealth/react';
import { useEffect, useRef, useState } from 'react';
import { Page } from 'react-pdf';
import {
  EbpMedicalHistoryField,
  getColorForField,
} from './EbpMedicalHistoryField';
import { MedicalHistoryLocateEvents } from './MedicalHistoryLocateEvents';

export default function PdfPageViewer({
  pageNumber,
  blocks,
  docPath,
  containerWidth,
  visible,
}: {
  pageNumber: number;
  blocks: (Aws.Textract.Block & { type: EbpMedicalHistoryField })[];
  docPath: string;
  containerWidth: number;
  visible: boolean;
}) {
  const canvasRef = useRef<any>(null);
  const divRef = useRef<HTMLDivElement>(null);

  // But of double re-rendering in PDF event with memoization
  // Counting every time the page is rendered and re-drawing the canvas
  const [renderedCount, setRenderedCount] = useState(0);

  const dispatchEvent = useEventDispatch();

  useEffect(() => {
    dispatchEvent(MedicalHistoryLocateEvents.PdfPageHeightEvent, {
      pageNumber,
      height: divRef.current?.clientHeight,
      docPath,
    });
  }, [
    // there is a need to subsribe to the visibility flag of the parent div (display: none | block)
    // otherwise divRef.current?.clientHeight will not be triggered
    visible,
    dispatchEvent,
    divRef.current?.clientHeight,
  ]);

  useEffect(() => {
    if (!containerWidth || !canvasRef.current || renderedCount === 0) {
      return;
    }

    const ctx = canvasRef.current.getContext('2d');

    if (!ctx) {
      return;
    }

    const { width, height } = canvasRef.current;

    for (const block of blocks.filter((b) => b.Geometry?.BoundingBox)) {
      ctx.strokeStyle = getColorForField(block.type);
      ctx.lineWidth = 2;
      ctx.strokeRect(
        width * block.Geometry!.BoundingBox!.Left! - 4,
        height * block.Geometry!.BoundingBox!.Top! - 4,
        width * block.Geometry!.BoundingBox!.Width! + 8,
        height * block.Geometry!.BoundingBox!.Height! + 8,
      );

      ctx.save();
    }
  }, [visible, canvasRef.current, containerWidth, renderedCount]);

  return (
    <div
      ref={divRef}
      style={{
        height: '100%',
        position: 'relative',
        paddingBottom: '4px',
        background: 'grey',
      }}
    >
      <Page
        canvasRef={canvasRef}
        pageNumber={pageNumber}
        renderTextLayer={false}
        renderAnnotationLayer={false}
        canvasBackground="white"
        loading={<Spinner />}
        width={containerWidth}
        onRenderSuccess={() => {
          setRenderedCount((prev) => prev + 1);
        }}
      />
      <div
        style={{
          position: 'absolute',
          bottom: '24px',
          left: '50%',
        }}
      >
        Page {pageNumber}
      </div>
    </div>
  );
}
