import {
  MycawFollowUpFormData,
  MycawInitialFormData,
} from '@packages/core-shared';

export const getOrderedFields = (
  form: MycawFollowUpFormData | MycawInitialFormData,
) => {
  const fields = Object.keys(form)
    .map((key) => form[key])
    .filter((field) => !!field.ref);
  fields.sort((a, b) => a.ref.localeCompare(b.ref));
  return fields;
};
