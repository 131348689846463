import { normalizeDoc } from '@packages/core-shared';
import { doc, getDoc } from 'firebase/firestore';
import { useEffect, useMemo, useState } from 'react';
import { useFirebase } from '../context';

export function useGetFirestoreDocument<TDoc>(
  tableName: string,
  id?: string | null,
  map: (doc: TDoc | null) => TDoc | null = (doc) => doc,
) {
  const { firestore } = useFirebase();

  const [record, setRecord] = useState<TDoc | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!tableName || !id) {
      setRecord(null);
      return;
    }

    const load = async () => {
      setLoading(true);
      setErrorMessage(null);

      try {
        const snapshot = await getDoc(doc(firestore, tableName, id));
        setRecord(map(normalizeDoc(snapshot)));
      } catch (err) {
        console.error(err);
        setErrorMessage(JSON.stringify(err));
      } finally {
        setLoading(false);
      }
    };

    load();
  }, [firestore, tableName, id]);

  const result = useMemo(
    () => ({ record, loading, errorMessage }),
    [record, loading, errorMessage],
  );

  return result;
}
