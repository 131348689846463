import { useEffect, useMemo, useRef, useState } from 'react';
import { useFormik, FormikProvider, FormikHelpers } from 'formik';
import debounce from 'lodash/debounce';
import styles from './CreateInitialAssessmentSummaryPage.module.scss';
import {
  EbpCase,
  CareDocumentType,
  displayEbpDocumentType,
  InitialAssessmentSummaryData,
  InitialAssessmentSummaryDataSchema,
  CareDocumentColumn,
  WorkFocusedPlan,
  Pcp,
  ReferralLandg,
  Referral,
  ReferralType,
  getFirstnameAndInitialOfLastname,
  OrganizationId,
  earliestDayTime,
  deepPartialOrNullable,
} from '@packages/core-shared';
import {
  AlertError,
  Button,
  Form,
  FormFieldset,
  FormikDatepicker,
  FormikInputText,
  FormikTextArea,
  Spinner,
} from '@percihealth/react';
import { useParams } from 'react-router-dom';
import { useGetFirestoreDocument } from '@packages/web-shared/hooks/useGetFirestoreDocument';
import { CareDocumentsApiRepository } from '@packages/web-shared/api/care-documents';
import { getDownloadURL, ref } from 'firebase/storage';
import OvercomeChallenges from '../components/OvercomeChallenges';
import ClinicalExperienceAndOutcomes from '../components/ClinicalExperienceAndOutcomes';
import { useMycawLoading } from '../components/useMycawLoading';
import DocumentResourceList from '../components/DocumentResourceList';
import DocumentTreatmentDetailsList from '../components/DocumentTreatmentDetailsList';
import DocumentColumns from '../components/DocumentColumns';
import { PdfGeneratedModal } from '../components/PdfGeneratedModal';
import populateClinicalAndExperienceOutcomes from '../populateClinicalAndExperienceOutcomes';
import { useGetFirstAppointmentWithCns } from '../useGetFirstAppointmentWithCns';
import InitialAssessmentRecommendedSupportTypes, {
  populateReferredServicesWithAppointnments,
} from './InitialAssessmentRecommendedSupportTypes';
import {
  useLoadPatientAppointmentsByService,
  useLoadServices,
} from '@packages/web-shared/api';
import { useFirebase } from '@packages/web-shared';

export default function CreateInitialAssessmentSummaryPage() {
  const params = useParams();
  const caseId = params?.caseId ?? null;

  if (!caseId) {
    return <div>Case ID (email) should be specified</div>;
  }

  const { storage, auth } = useFirebase();

  const [openPdfModal, setOpenPdfModal] = useState(false);
  useEffect(() => {
    setOpenPdfModal(false);
  }, [caseId]);

  const docsApiRepository = useMemo(
    () => new CareDocumentsApiRepository(auth),
    [auth],
  );

  const {
    record: ebpCase,
    loading: caseLoading,
    errorMessage: caseLoadingErrorMessage,
  } = useGetFirestoreDocument<EbpCase>('cases', caseId);

  const servicesLoader = useLoadServices();

  const appointmentsByServiceLoader = useLoadPatientAppointmentsByService({
    patientEmail: ebpCase?.patient.email,
  });

  const firstAppointmentWithCns = useGetFirstAppointmentWithCns(
    ebpCase?.patient?.email,
  );

  const migrateOldDataStructure = (
    doc: InitialAssessmentSummaryData | null,
  ) => {
    // Migration for old data structure
    if (doc) {
      if (!doc.hna) {
        doc.hna = defaultHna;
      }

      if (doc.clinicalAndExperienceOutcomes?.otherFactors) {
        delete doc.clinicalAndExperienceOutcomes.otherFactors;
      }

      if (
        doc.patient &&
        !doc.patient.level &&
        ebpCase?.organization.id === OrganizationId.landg
      ) {
        doc.patient.level = ebpCase?.patient.level;
      }

      if (
        doc.patient &&
        doc.patient.work &&
        doc.patient.work.hideHopingToReturnToWorkIn === undefined
      ) {
        doc.patient.work.hideHopingToReturnToWorkIn = false;
      }

      // professionalType is renamed to service: {id, name, professionalType}
      // supportType is renamed to service: {id, name, professionalType}
      if (
        doc.professionalsReferred &&
        doc.professionalsReferred.some(
          (pr: any) => pr.professionalType || pr.supportType,
        )
      ) {
        doc.professionalsReferred = [...doc.professionalsReferred].map(
          (pr: any) => {
            const { professionalType, supportType, ...rest } = pr;

            if (!rest.service?.professionalType) {
              return {
                ...rest,
                // id and name will be corrected later in the populate method
                service: {
                  professionalType: professionalType ?? supportType,
                },
              };
            }
            return pr;
          },
        );
      }
    }

    return doc;
  };

  const docLoader = useGetFirestoreDocument<InitialAssessmentSummaryData>(
    'documents',
    ebpCase?.documents?.initialAssessmentSummary?.id ?? null,
    migrateOldDataStructure,
  );

  const docAutosaved = useGetFirestoreDocument<InitialAssessmentSummaryData>(
    `cases/${caseId}/documents-autosaved`,
    CareDocumentType.InitialAssessmentSummary,
    migrateOldDataStructure,
  );

  const [autosaveError, setAutosaveError] = useState<string | null>(null);
  useEffect(() => {
    setAutosaveError(null);
  }, [caseId]);

  const autoSave = async (values: any) => {
    console.log('autosave');
    setAutosaveError(null);
    try {
      values.caseId = caseId;
      await docsApiRepository.autosaveInitialAssessmentSummaryForm(values);
    } catch (error: unknown) {
      console.error(error);
      setAutosaveError('Failed to autosave');
    }
  };
  const debouncedAutosave = useRef(debounce(autoSave, 2000));

  const workFocusedDocLoader = useGetFirestoreDocument<WorkFocusedPlan>(
    'documents',
    ebpCase?.documents?.workFocusedPlan?.id ?? null,
  );

  const pcpDocLoader = useGetFirestoreDocument<Pcp>(
    'documents',
    ebpCase?.documents?.pcp?.id ?? null,
  );

  const [createResponseError, setCreateResponseError] = useState<string | null>(
    null,
  );

  const handleOnSubmit = async (
    values: InitialAssessmentSummaryData,
    actions: FormikHelpers<InitialAssessmentSummaryData>,
  ) => {
    try {
      actions.setSubmitting(true);
      setCreateResponseError(null);
      const { storageRef } =
        await docsApiRepository.createInitialAssessmentSummary(values);

      const previewUrl = await getDownloadURL(ref(storage, storageRef));

      setOpenPdfModal(true);

      // Open the file in a new tab, so it is not automatically downloaded to the local storage
      window.open(previewUrl, '_blank');
    } catch (error: unknown) {
      let errorMsg = '';

      if (error instanceof Error) {
        errorMsg = error.message;
      } else {
        errorMsg = JSON.stringify(error);
      }

      console.error(errorMsg);
      setCreateResponseError(errorMsg);
    } finally {
      actions.setSubmitting(false);
    }
  };

  const { record: referral } = useGetFirestoreDocument<Referral>(
    'referrals',
    ebpCase?.referralId,
  );
  const gipCode = useMemo(() => {
    if (referral?.type !== ReferralType.landg) {
      return '';
    }

    return (referral as ReferralLandg)?.gip?.code ?? '';
  }, [referral]);

  const mycawLoader = useMycawLoading({
    ebpCase: ebpCase,
  });

  const formikDependencies = [
    docLoader.record,
    docAutosaved.record,
    pcpDocLoader.record,
    workFocusedDocLoader.record,
    mycawLoader,
    ebpCase,
    gipCode,
    firstAppointmentWithCns,
    servicesLoader.services,
    appointmentsByServiceLoader.records,
  ];

  const initialValues: InitialAssessmentSummaryData = useMemo(() => {
    let docData = docAutosaved.record ?? docLoader.record;
    if (docData) {
      // Deep clone
      try {
        // schema cast is required to have Date type instead of strings
        docData = deepPartialOrNullable(
          InitialAssessmentSummaryDataSchema,
        ).cast(
          JSON.parse(JSON.stringify(docData)),
        ) as InitialAssessmentSummaryData;
      } catch {
        // intentionally left blank
      }
    }
    const result =
      docData ??
      ({
        caseId,
        type: CareDocumentType.InitialAssessmentSummary,
        patient: {
          fullName: ebpCase?.patient
            ? getFirstnameAndInitialOfLastname(ebpCase.patient)
            : '',
          dob:
            ebpCase?.patient?.dateOfBirth ??
            workFocusedDocLoader.record?.patient?.dob ??
            earliestDayTime(new Date()),
          cancerType:
            ebpCase?.patient?.medicalHistory?.sentToHealee?.cancerType ?? '',
          dateOfDiagnosis: new Date(),
          work: {
            dateSignedOff:
              workFocusedDocLoader.record?.patient?.work?.dateSignedOff ??
              new Date(),
            hideHopingToReturnToWorkIn: false,
            hopingToReturnToWorkIn:
              workFocusedDocLoader.record?.patient?.work
                ?.hopingToReturnToWorkIn ?? '',
            jobTitle:
              workFocusedDocLoader.record?.patient?.work?.jobTitle ?? '',
            workPattern:
              workFocusedDocLoader.record?.patient?.work?.workPattern ?? '',
          },
          level: ebpCase?.patient.level,
        },
        perciStartDate:
          firstAppointmentWithCns?.date ??
          new Date(new Date().getFullYear(), 1, 1),
        gip: {
          code: gipCode,
        },
        dateOfConsultation: new Date(),

        treatmentDetails: {
          treatment: '',
          expectedEndDate: '',
          plan: '',
          other: 'None',
        },

        hna: mycawLoader.impacts
          ? [
              {
                header: 'Physical impacts',
                values: mycawLoader.impacts.physical,
              },
              {
                header: 'Psychological impacts',
                values: mycawLoader.impacts.psychological,
              },
              {
                header: 'Social impacts',
                values: mycawLoader.impacts.affectedRelationships,
              },
            ]
          : defaultHna,

        planToOvercomeChallenges:
          workFocusedDocLoader.record?.planToOvercomeChallenges ?? [],

        clinicalAndExperienceOutcomes: {
          mycawConcern1: mycawLoader.mycawConcern1 ?? {},
          mycawConcern2: mycawLoader.mycawConcern2 ?? {},
          overallWellbeing: mycawLoader.overallWellbeing ?? {},
          mostImportantAspectOfSupport:
            mycawLoader.mostImportantAspectOfSupport,
          // Other factors are not used in this report
          otherFactors: undefined,
          returnToWorkSelfEfficacy: mycawLoader.returnToWorkSelfEfficacy ?? {},
          fatigue: mycawLoader.fatigue ?? {},
        },
        professionalsReferred:
          pcpDocLoader.record?.patientServices?.map((ps) => ({
            // old structure support
            service: {
              id: ps.service?.id,
              name: ps.service?.name,
              professionalType:
                servicesLoader.services[ps.service?.id ?? '-1']
                  ?.professional_type,
            },
            concern: ps.concerns?.join(', ') ?? '',
            professional: ps.professional,
            status: ps.status,
          })) ?? [],
        expertSummary: '',

        signPosting: pcpDocLoader.record?.resources ?? [],
        communicationNotes: '',
      } satisfies InitialAssessmentSummaryData);

    // Correct old autosaved data
    populateClinicalAndExperienceOutcomes(
      result.clinicalAndExperienceOutcomes,
      mycawLoader,
      { removeOtheFactors: true },
    );

    result.professionalsReferred = populateReferredServicesWithAppointnments(
      result.professionalsReferred,
      servicesLoader.services,
      appointmentsByServiceLoader.records,
      pcpDocLoader.record?.patientServices ?? [],
    );

    // populate empty concern
    if (pcpDocLoader.record?.patientServices) {
      for (const profRef of result.professionalsReferred.filter(
        (pr) => !pr.concern,
      )) {
        profRef.concern =
          pcpDocLoader.record?.patientServices
            .find(
              (ps) => ps.service?.id && ps.service.id === profRef.service.id,
            )
            ?.concerns?.join(', ') ?? '';
      }
    }

    return result;
  }, [...formikDependencies]);

  const formik = useFormik({
    enableReinitialize: true,
    isInitialValid:
      InitialAssessmentSummaryDataSchema.isValidSync(initialValues),
    initialValues: initialValues,
    validationSchema: InitialAssessmentSummaryDataSchema,
    onSubmit: handleOnSubmit,
  });

  useEffect(() => {
    formik.resetForm();
  }, [...formikDependencies]);

  useEffect(() => {
    if (formik?.dirty) {
      debouncedAutosave.current(formik.values);
    }
  }, [formik?.values, formik?.dirty]);

  if (caseLoading || docLoader.loading) {
    return <Spinner />;
  }

  if (caseLoadingErrorMessage || !ebpCase) {
    return (
      <AlertError>{caseLoadingErrorMessage ?? 'Not found case'}</AlertError>
    );
  }

  if (docLoader.errorMessage) {
    return <AlertError>{docLoader.errorMessage}</AlertError>;
  }

  return (
    <FormikProvider value={formik}>
      <div>
        {autosaveError && <AlertError>{autosaveError}</AlertError>}
        <Form onSubmit={formik.handleSubmit}>
          <FormFieldset title="Patient's details">
            <FormikInputText
              id="patient.fullName"
              name="patient.fullName"
              required
              label="Patient full name"
              value={formik.values.patient.fullName}
            />
            <FormikDatepicker
              id="patient.dob"
              name="patient.dob"
              required
              label="Patient DOB"
              className="w-full"
              value={formik.values.patient.dob}
            />
            <FormikInputText
              id="patient.cancerType"
              name="patient.cancerType"
              required
              label="Cancer type"
              value={formik.values.patient.cancerType}
            />
            <FormikDatepicker
              id="patient.dateOfDiagnosis"
              name="patient.dateOfDiagnosis"
              required
              label="Date of diagnosis"
              value={formik.values.patient.dateOfDiagnosis}
            />
          </FormFieldset>

          <FormFieldset title="Care details">
            <FormikDatepicker
              id="perciStartDate"
              name="perciStartDate"
              required
              label="First appointment date with CNS"
              value={formik.values.perciStartDate}
            />
            <FormikDatepicker
              id="dateOfConsultation"
              name="dateOfConsultation"
              required
              label="Date of consultation"
              value={formik.values.dateOfConsultation}
            />
            <FormikInputText
              id="gip.code"
              name="gip.code"
              required
              disabled={!!gipCode}
              label="Group income protection code"
              value={formik.values.gip.code}
            />
          </FormFieldset>

          <DocumentTreatmentDetailsList formik={formik} />

          <FormFieldset title="Patient work details">
            <FormikDatepicker
              id="patient.work.dateSignedOff"
              name="patient.work.dateSignedOff"
              required
              label="Date signed-off work"
              value={formik.values.patient.work.dateSignedOff}
            />
            <FormikInputText
              id="patient.work.jobTitle"
              name="patient.work.jobTitle"
              required
              label="Job title"
              value={formik.values.patient.work.jobTitle}
            />
            <FormikInputText
              id="patient.work.workPattern"
              name="patient.work.workPattern"
              label="Work pattern"
              value={formik.values.patient.work.workPattern}
            />
            <FormikInputText
              id="patient.work.hopingToReturnToWorkIn"
              name="patient.work.hopingToReturnToWorkIn"
              required
              label="Hoping to return to work in"
              value={formik.values.patient.work.hopingToReturnToWorkIn}
              checked={!formik.values.patient.work.hideHopingToReturnToWorkIn}
              disabled={formik.values.patient.work.hideHopingToReturnToWorkIn}
              onCheckedChange={() => {
                const newHideHopingToReturnToWorkIn =
                  !formik.values.patient.work.hideHopingToReturnToWorkIn;
                if (newHideHopingToReturnToWorkIn) {
                  formik.setFieldValue(
                    'patient.work.hopingToReturnToWorkIn',
                    '',
                    true,
                  );
                }
                formik.setFieldValue(
                  'patient.work.hideHopingToReturnToWorkIn',
                  newHideHopingToReturnToWorkIn,
                  true,
                );
              }}
            />
          </FormFieldset>

          <FormFieldset title="Cancer nurse specialist summary">
            <FormikTextArea
              className="full-width"
              name="expertSummary"
              rows={8}
              label="Summary notes"
              required
              value={formik.values.expertSummary}
            />
          </FormFieldset>

          <FormFieldset title="Member holistic needs assessment (HNA)">
            <DocumentColumns
              columns={formik.values.hna}
              onChange={(arr) => {
                formik.setFieldValue('hna', arr);
              }}
            />
          </FormFieldset>

          <FormFieldset title="Plan to help overcome challenges to getting back to work">
            <OvercomeChallenges
              values={formik.values.planToOvercomeChallenges}
              onChange={(arr) => {
                formik.setFieldValue('planToOvercomeChallenges', arr);
              }}
            />
          </FormFieldset>

          <FormFieldset title="Clinical outcomes">
            <ClinicalExperienceAndOutcomes
              clinicalAndExperienceOutcomes={
                formik.values.clinicalAndExperienceOutcomes
              }
            />
          </FormFieldset>

          <FormFieldset title="Recommended Perci Health support types">
            {servicesLoader.servicesError && (
              <AlertError>{servicesLoader.servicesError}</AlertError>
            )}
            {servicesLoader.servicesLoading && <Spinner />}
            {!servicesLoader.servicesLoading && (
              <InitialAssessmentRecommendedSupportTypes
                onChange={(arr) =>
                  formik.setFieldValue('professionalsReferred', arr, true)
                }
                values={formik.values.professionalsReferred}
                services={servicesLoader.services}
              />
            )}
          </FormFieldset>

          <FormFieldset title="Signposting provided">
            <DocumentResourceList
              resources={formik.values.signPosting}
              onChange={(updated) =>
                formik.setFieldValue('signPosting', updated)
              }
            />
          </FormFieldset>

          <FormFieldset title="Communication sent to non-Perci Healthcare team">
            <FormikTextArea
              rows={3}
              name="communicationNotes"
              className="full-width"
              value={formik.values.communicationNotes}
            />
          </FormFieldset>

          <hr />
          <div className={styles.full}>
            <Button
              type="submit"
              className={styles.submit}
              fullWidth
              disabled={!formik.isValid}
              submitting={formik.isSubmitting}
            >
              Create{' '}
              {displayEbpDocumentType(
                CareDocumentType.InitialAssessmentSummary,
              )}
            </Button>
          </div>
          {createResponseError && (
            <AlertError>{createResponseError}</AlertError>
          )}
        </Form>
      </div>
      <PdfGeneratedModal
        caseId={caseId}
        open={openPdfModal}
        onClose={() => setOpenPdfModal(false)}
      />
    </FormikProvider>
  );
}

export const defaultHna: CareDocumentColumn[] = [
  {
    header: 'Physical impacts',
    values: [],
  },
  {
    header: 'Psychological impacts',
    values: [],
  },
  {
    header: 'Social impacts',
    values: [],
  },
];
