import { normalizeDoc } from '@packages/core-shared';
import { doc, onSnapshot } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useFirebase } from '../context';

export function useSubscribeFirestoreDocument<TDoc>(
  tableName: string,
  id?: string | null,
) {
  const { firestore } = useFirebase();

  const [record, setRecord] = useState<TDoc | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!id) {
      setRecord(null);
      setLoading(false);
      return;
    }

    const unsubscribe = onSnapshot(
      doc(firestore, tableName, id),
      (snapshot) => {
        let row = normalizeDoc<TDoc>(snapshot);
        setRecord(row);
        setErrorMessage(null);
        setLoading(false);
      },
      (err) => {
        console.error(err);
        setLoading(false);
        setErrorMessage(JSON.stringify(err));
      },
    );
    return () => unsubscribe();
  }, [firestore, tableName, id]);

  return {
    record,
    loading,
    errorMessage,
  };
}
