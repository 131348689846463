import { MycawInitialForm } from '@packages/core-shared';
import { getInitialValue } from './getInitialValue';
import { classList } from '@percihealth/react';
import styles from './MycawFollowUpFormAnswer.module.scss';

interface Props {
  value: string;
  fieldRef: string;
  latestInitialForm: MycawInitialForm | null;
}

export const MycawFollowUpFormAnswer = ({
  value,
  fieldRef,
  latestInitialForm,
}: Props) => {
  const initialValue = getInitialValue(fieldRef, latestInitialForm);
  const increasedOrEqual: boolean | undefined =
    // initial value can be 0, checking for undefined and null
    initialValue !== undefined &&
    initialValue !== null &&
    !isNaN(Number(value)) &&
    !isNaN(Number(initialValue))
      ? Number(value) >= Number(initialValue)
      : undefined;

  return (
    <div>
      <div
        className={classList(
          styles.answer,
          increasedOrEqual !== undefined
            ? increasedOrEqual
              ? styles.increased
              : styles.decreased
            : undefined,
        )}
      >
        {value}
      </div>
      {/* initial value can be 0, checking for undefined and null */}
      {initialValue !== undefined && initialValue !== null && (
        <div className={styles.initial}>initial: {initialValue}</div>
      )}
    </div>
  );
};
