import { EndOfCareSummaryData } from '@packages/core-shared';
import { FormFieldset } from '@percihealth/react';
import { DocumentCheckableControl } from '../components/DocumentCheckableControl';

export default function ReturnToWorkStatus({
  formik,
}: {
  formik: {
    values: EndOfCareSummaryData;
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean,
    ) => void;
  };
}) {
  return (
    <FormFieldset title="Return to work status">
      <p className="description full-width">
        Unchecked items will not be shown in the "Return to work status"
        section. The whole section will not be displayed when all items are
        unchecked.
      </p>
      <DocumentCheckableControl
        name="Returned to work"
        checked={!!formik.values.returnToWorkStatus?.returnToWork?.checked}
        value={formik.values.returnToWorkStatus?.returnToWork?.value}
        placeholder="Example: 30 SEP 2025"
        onChange={(v) => {
          formik.setFieldValue('returnToWorkStatus.returnToWork', v);
        }}
      />
      <DocumentCheckableControl
        name="Return to work date agree"
        checked={
          !!formik.values.returnToWorkStatus?.returnToWorkDateAgreed?.checked
        }
        value={formik.values.returnToWorkStatus?.returnToWorkDateAgreed?.value}
        onChange={(v) => {
          formik.setFieldValue('returnToWorkStatus.returnToWorkDateAgreed', v);
        }}
      />
      <DocumentCheckableControl
        name="Intends to return to work"
        checked={
          !!formik.values.returnToWorkStatus?.intendsToReturnToWork?.checked
        }
        value={formik.values.returnToWorkStatus?.intendsToReturnToWork?.value}
        onChange={(v) => {
          formik.setFieldValue('returnToWorkStatus.intendsToReturnToWork', v);
        }}
      />
      <DocumentCheckableControl
        name="Unable to return to work"
        checked={!!formik.values.returnToWorkStatus?.caseManagerReview?.checked}
        value={formik.values.returnToWorkStatus?.caseManagerReview?.value}
        onChange={(v) => {
          formik.setFieldValue('returnToWorkStatus.caseManagerReview', v);
        }}
      />
    </FormFieldset>
  );
}
