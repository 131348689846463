import { ApiRepository } from '../../helpers/ApiRepository';

export class FormsApiRepository extends ApiRepository {
  async updateSafeguard(formId: string, safeguardCheck: boolean) {
    const token = await this.getToken();
    if (!token) {
      throw new Error('Unauthorized');
    }

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/forms/${formId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        method: 'PUT',
        body: JSON.stringify({ safeguardCheck }),
      },
    );

    if (!response.ok) {
      throw new Error('Error updating safe guard checkmark');
    }
  }
}
