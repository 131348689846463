import styles from './DocumentsUsedList.module.css';

export const DocumentsUsedList = ({
  documents,
}: {
  documents: (string | undefined)[];
}) => {
  return (
    <div className={styles.container}>
      <p>The form is generated based on the following documents:</p>

      <table className={styles.table}>
        <thead>
          <tr>
            <th>Document Name</th>
          </tr>
        </thead>

        <tbody>
          {documents.map((document) => (
            <tr>
              <td>{document}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <br />
    </div>
  );
};
