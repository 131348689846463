import { Appointment, AppointmentStatus } from '@packages/core-shared';
import { useEffect, useMemo, useState } from 'react';
import { useLoadPatientAppointments } from './useLoadPatientAppointments';

export const useLoadProfessionalsSeen = ({
  patientEmail,
}: {
  patientEmail?: string;
}) => {
  const appointmentsLoader = useLoadPatientAppointments({
    patientEmail,
    status: AppointmentStatus.completed,
  });

  const [professionalsSeen, setProfessionalsSeen] = useState<
    Record<string, Appointment[]>
  >({});

  useEffect(() => {
    // filter out missed appointments
    const apps = appointmentsLoader.records.filter((a) => !a.noShow);

    let servicesVisited = apps.flatMap(
      (app) =>
        app.services?.filter((s) => !!s)?.map((s) => s!.toString()) ?? [],
    );
    // unique
    servicesVisited = servicesVisited.filter(
      (value, index, array) => array.indexOf(value) === index,
    );

    const aggregatedServices = servicesVisited.reduce(
      (acc, serviceId) => {
        acc[serviceId] = apps.filter((app) =>
          app.services?.map((s) => s?.toString())?.includes(serviceId),
        );

        return acc;
      },
      {} as Record<string, Appointment[]>,
    );

    setProfessionalsSeen(aggregatedServices);
  }, [appointmentsLoader.records]);

  const result = useMemo(() => {
    return {
      professionalsSeen,
      loading: appointmentsLoader.loading,
      errorMessage: appointmentsLoader.errorMessage,
    };
  }, [
    professionalsSeen,
    appointmentsLoader.loading,
    appointmentsLoader.errorMessage,
  ]);

  return result;
};
