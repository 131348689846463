import { ThemeModeToggler } from '@percihealth/react';
import styles from './Footer.module.css';

export default function Footer() {
  return (
    <footer className={styles.footer}>
      <div className={styles['left-block']}>
        ©&nbsp;2024 Perci Health. All rights reserved.
      </div>
      <div className={styles['right-block']}>
        <ThemeModeToggler />
      </div>
    </footer>
  );
}
