import { FormLabel } from '@percihealth/react';
import styles from './MycawFollowUpFormComponent.module.scss';
import {
  MycawFollowUpForm,
  MycawInitialForm,
  dateTimeToLocalDateTimeString,
} from '@packages/core-shared';
import { getOrderedFields } from '../../getOrderedFields';
import { getCoercedFormTitle } from '../../getCoercedFormTitle';
import { MycawFollowUpFormAnswer } from './MycawFollowUpFormAnswer';

interface Props {
  followUpForm: MycawFollowUpForm;
  latestInitialForm: MycawInitialForm | null;
}

export default function MycawFollowUpFormComponent({
  followUpForm,
  latestInitialForm,
}: Props) {
  return (
    <div className={styles.container}>
      <div>
        <FormLabel bold>Time to complete</FormLabel>
        <span>{followUpForm.time_to_complete}</span>
      </div>
      <div>
        <FormLabel bold>Created</FormLabel>
        <span>{dateTimeToLocalDateTimeString(followUpForm.createdAt)}</span>
      </div>
      <div className="full-width">
        <div>
          <FormLabel bold>Typeform responses</FormLabel>
          <ol className={styles['latest-form']}>
            {getOrderedFields(followUpForm).map((field) => (
              <li key={field.ref} className={styles.qa}>
                {getCoercedFormTitle(field.title, followUpForm)}
                <MycawFollowUpFormAnswer
                  fieldRef={field.ref}
                  value={field.value}
                  latestInitialForm={latestInitialForm}
                />
              </li>
            ))}
          </ol>
        </div>
      </div>
    </div>
  );
}
