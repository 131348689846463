import { ebpCaseEmails } from '@packages/core-shared';
import { AlertError, Button, Modal } from '@percihealth/react';
import { useMemo, useState } from 'react';
import { EmailsApiRepository } from '../../../../../api';
import { useFirebase } from '../../../../../context';

interface Props {
  caseId: string;
  pcpId: string | null;
  onClose: () => void;
}

export const PcpToHealixModal = ({ caseId, pcpId, onClose }: Props) => {
  const { auth } = useFirebase();

  const emailsApiRepository = useMemo(
    () => new EmailsApiRepository(auth),
    [auth],
  );

  const [isSending, setIsSending] = useState(false);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const sendPcpEmail = async () => {
    if (!pcpId) {
      return;
    }

    try {
      setIsSending(true);
      setErrorMessage(null);
      await emailsApiRepository.resend({
        caseId,
        documentId: pcpId,
        template: ebpCaseEmails.PcpToHealixNurse.id,
      });
    } catch (err) {
      console.error(err);
      setErrorMessage(err instanceof Error ? err.message : JSON.stringify(err));
    } finally {
      setIsSending(false);
    }
  };

  return (
    <Modal open={!!pcpId} onClose={onClose} center>
      <h3>Healix - email sending confirmation</h3>
      <p>
        You are going to send "Personal care plan" to a Healix nurse.
        <br />
        Proceed?
        <br />
        <br />
      </p>
      {errorMessage && <AlertError>{errorMessage}</AlertError>}
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button onClick={onClose} disabled={isSending}>
          Cancel
        </Button>

        <Button
          level="secondary"
          submitting={isSending}
          onClick={async () => {
            await sendPcpEmail();
            onClose();
          }}
        >
          Send
        </Button>
      </div>
    </Modal>
  );
};
