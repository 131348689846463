import { Appointment } from '@packages/core-shared';

import styles from './AppointmentsTranscriptionsList.module.css';
import { dateTimeToLondonDateTimeString } from '@packages/core-shared';
import { useLoadServices } from '@packages/web-shared/api';

export const AppointmentsTranscriptionsList = ({
  appointments,
}: {
  appointments: Appointment[];
}) => {
  const servicesLoader = useLoadServices();

  return (
    <div className={styles.container}>
      <p>
        The form is generated based on the following appointments and their
        transcriptions:
      </p>

      <table className={styles.table}>
        <thead>
          <tr>
            <th>Apt. ID</th>
            <th>Service</th>
            <th>Apt. time</th>
            <th>Transcription time</th>
          </tr>
        </thead>

        <tbody>
          {appointments.map((apt) => (
            <tr>
              <td>{apt.id}</td>
              <td>
                {apt.services
                  ?.filter(Boolean)
                  .map((srv) => (
                    <div key={srv}>
                      {servicesLoader.services[srv!.toString()]?.name}
                    </div>
                  ))}
              </td>
              <td>{dateTimeToLondonDateTimeString(apt.date)}</td>
              <td>
                {apt.transcriptions?.map((tr) => (
                  <div key={tr.path}>
                    {' '}
                    {dateTimeToLondonDateTimeString(tr.createdAt)}
                  </div>
                ))}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <p>
        The missed transcription may negatively result on the AI form
        generation. If the appointment transcription is missed, please try again
        in several minutes. It may take up to 10 minutes to transcribe an
        appointment.
      </p>
    </div>
  );
};
