import { CareDocumentColumn } from '@packages/core-shared';
import DocumentColumnList from './DocumentColumnList';
import styles from './DocumentColumns.module.css';

export default function DocumentColumns({
  columns,
  onChange,
}: {
  columns: CareDocumentColumn[];
  onChange: (arr: CareDocumentColumn[]) => void;
}) {
  return (
    <div className="full-width">
      <div className={styles['impacts-container']}>
        {columns.map((v) => (
          <div key={v.header}>
            <div className={styles.title}>{v.header}</div>
            <DocumentColumnList
              rows={v.values ?? []}
              onChange={(values) => {
                const arr = columns.map((vv) => {
                  if (vv.header === v.header) {
                    return { ...vv, values } satisfies CareDocumentColumn;
                  }
                  return vv;
                });

                onChange(arr);
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
