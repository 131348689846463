import { AlertError, Button } from '@percihealth/react';
import { TextArea } from '@percihealth/react';
import { useState } from 'react';
import { CareDocumentOvercomeChallengeRecommendation } from '@packages/core-shared';
import buildReactKey from './buildReactKey';
import styles from './RecommendationList.module.css';

export default function RecommendationList({
  recommendations,
  onChange,
}: {
  recommendations: CareDocumentOvercomeChallengeRecommendation[];
  onChange: (arr: CareDocumentOvercomeChallengeRecommendation[]) => void;
}) {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [recommendation, setRecommendation] = useState('');
  const [timeframe, setTimeframe] = useState('');

  const add = () => {
    if (!recommendation || recommendation.trim().length === 0) {
      setErrorMessage('Recommendation should not be empty');
      return;
    } else if (!timeframe || timeframe.trim().length === 0) {
      setErrorMessage('Timeframe should not be empty');
      return;
    } else if (
      recommendations.some(
        (res) =>
          buildReactKey(res) === buildReactKey({ recommendation, timeframe }),
      )
    ) {
      setErrorMessage('the same recommendation with timeframe already exists');
      return;
    }

    setErrorMessage(null);
    setRecommendation('');
    setTimeframe('');
    onChange([...recommendations, { recommendation, timeframe }]);
  };

  const remove = (toRemove: CareDocumentOvercomeChallengeRecommendation) => {
    onChange(recommendations.filter((res) => res !== toRemove));
  };

  return (
    <div>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Recommendations</th>
            <th>Timeframe</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {recommendations.map((row) => (
            <tr key={buildReactKey(row)}>
              <td>{row.recommendation}</td>
              <td>{row.timeframe}</td>
              <td>
                <a onClick={() => remove(row)}>Delete</a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className={styles['add-container']}>
        <TextArea
          label="Recommendation"
          value={recommendation}
          rows={4}
          onChange={(e) => setRecommendation(e.target.value)}
        />
        <TextArea
          label="Timeframe"
          value={timeframe}
          rows={4}
          onChange={(e) => setTimeframe(e.target.value)}
        />
        <Button type="button" className={styles.add} onClick={add}>
          Add
          <br />
          recommendation
        </Button>
      </div>
      {errorMessage && <AlertError>{errorMessage}</AlertError>}
    </div>
  );
}
