import {
  EbpCaseStatus,
  dateTimeToLondonDateTimeString,
} from '@packages/core-shared';
import styles from './CaseStatusBar.module.scss';
import { classList } from '@percihealth/react';

export default function CaseStatusBar({
  status,
  createdAt,
  dischargeAt,
}: {
  status: EbpCaseStatus;
  createdAt: Date;
  dischargeAt?: Date;
}) {
  return (
    <div className={classList(styles.container, styles[status])}>
      {status === EbpCaseStatus.discharged && (
        <div>
          <b>DISCHARGED</b>
          <div> Start: {dateTimeToLondonDateTimeString(createdAt)}</div>
          <div> End: {dateTimeToLondonDateTimeString(dischargeAt)}</div>
        </div>
      )}
    </div>
  );
}
