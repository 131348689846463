import { Appointment } from '@packages/core-shared';
import { collection, orderBy, query, where } from 'firebase/firestore';
import { useMemo } from 'react';
import { useSubscribeFirestoreList } from '../../hooks/useSubscribeFirestoreList';
import { useFirebase } from '../../context';

export const useSubscribePatientAppointments = ({
  patientEmail,
  status,
}: {
  patientEmail?: string;
  status?: string;
}) => {
  const { firestore } = useFirebase();

  const queryResult = useMemo(() => {
    let q = query(
      collection(firestore, `appointments`),
      where(
        'patientEmail',
        '==',
        patientEmail?.toLocaleLowerCase() ?? '__UNKNOWN_PATIENT__',
      ),
    );

    if (status) {
      q = query(q, where('status', '==', status));
    }

    q = query(q, orderBy('date', 'desc'));
    return q;
  }, [firestore, patientEmail, status]);

  const result = useSubscribeFirestoreList<Appointment>(queryResult);

  return result;
};
