import {
  CareDocumentClinicalAndExperienceOutcomes,
  CareDocumentOutcome,
} from '@packages/core-shared';
type MyCawLoaderResult = {
  mycawConcern1: CareDocumentOutcome;
  mycawConcern2: CareDocumentOutcome;
  overallWellbeing: CareDocumentOutcome;
  returnToWorkSelfEfficacy: CareDocumentOutcome;
  mostImportantAspectOfSupport?: CareDocumentOutcome;
  otherFactors?: CareDocumentOutcome;
  fatigue: CareDocumentOutcome;
};

/*
 * Populates the Clinical outcomes with the fresh data from mycaw loader result.
 */
export default function populateClinicalAndExperienceOutcomes(
  clinicalAndExperienceOutcomes: CareDocumentClinicalAndExperienceOutcomes,
  myCawLoaderResult?: MyCawLoaderResult,
  settings?: {
    removeOtheFactors?: boolean;
  },
) {
  if (myCawLoaderResult?.mycawConcern1) {
    clinicalAndExperienceOutcomes.mycawConcern1 =
      myCawLoaderResult.mycawConcern1;
  }
  if (myCawLoaderResult?.mycawConcern2) {
    clinicalAndExperienceOutcomes.mycawConcern2 =
      myCawLoaderResult.mycawConcern2;
  }

  if (myCawLoaderResult?.overallWellbeing) {
    clinicalAndExperienceOutcomes.overallWellbeing =
      myCawLoaderResult.overallWellbeing;
  }

  // mostImportantAspectOfSupport can be undefined, which means it should not be shown
  if (myCawLoaderResult) {
    clinicalAndExperienceOutcomes.mostImportantAspectOfSupport =
      myCawLoaderResult.mostImportantAspectOfSupport;
  }

  // otherFactors can be undefined, which means it should not be shown
  if (settings?.removeOtheFactors) {
    clinicalAndExperienceOutcomes.otherFactors = undefined;
  } else if (myCawLoaderResult) {
    clinicalAndExperienceOutcomes.otherFactors = myCawLoaderResult.otherFactors;
  }

  if (myCawLoaderResult?.fatigue) {
    clinicalAndExperienceOutcomes.fatigue = myCawLoaderResult.fatigue;
  }

  if (myCawLoaderResult?.returnToWorkSelfEfficacy) {
    clinicalAndExperienceOutcomes.returnToWorkSelfEfficacy = {
      initial: myCawLoaderResult.returnToWorkSelfEfficacy.initial,
      followUp:
        myCawLoaderResult.returnToWorkSelfEfficacy.followUp ??
        clinicalAndExperienceOutcomes.returnToWorkSelfEfficacy.followUp,
      change:
        myCawLoaderResult.returnToWorkSelfEfficacy.change ??
        clinicalAndExperienceOutcomes.returnToWorkSelfEfficacy.change,
    };
  }
}
