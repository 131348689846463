import { IObject, normalizeDocs } from '@packages/core-shared';
import {
  DocumentData,
  getDocs,
  limit,
  Query,
  query,
  QueryDocumentSnapshot,
  startAfter,
} from 'firebase/firestore';
import { useCallback, useState } from 'react';

export function useLoadFirestoreList<
  TDoc extends IObject.Timestamped<unknown>,
  TResult extends TDoc = TDoc,
>(
  q: Query<DocumentData>,
  pageSize = Number(process.env.REACT_APP_PAGE_SIZE ?? 50),
  map?: (doc: TDoc) => TResult,
) {
  const [records, setRecords] = useState<TResult[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [canLoadNextPage, setCanLoadNextPage] = useState(false);
  const [lastDocRecord, setLastDocRecord] = useState<QueryDocumentSnapshot<
    DocumentData,
    DocumentData
  > | null>(null);

  const load = useCallback(
    (lastDoc?: QueryDocumentSnapshot<DocumentData, DocumentData> | null) => {
      setLoading(true);

      q = query(q, limit(pageSize + 1));
      if (lastDoc) {
        q = query(q, startAfter(lastDoc));
      }

      getDocs(q).then(
        (snapshot) => {
          setErrorMessage(null);
          setLoading(false);
          setLastDocRecord(
            snapshot.docs.length > 0
              ? snapshot.docs[snapshot.docs.length - 1]
              : null,
          );

          let newRows = normalizeDocs<TResult>(snapshot.docs);
          setCanLoadNextPage(newRows.length >= pageSize);

          setRecords((prev) => {
            if (map) {
              newRows = newRows.map(map);
            }
            if (lastDoc) {
              return prev.concat(newRows);
            }

            return newRows;
          });
        },
        (err) => {
          console.error(err);
          setLoading(false);
          setErrorMessage(JSON.stringify(err));
        },
      );
    },
    [q, pageSize, map],
  );

  const loadNextPage = useCallback(() => {
    if (canLoadNextPage && lastDocRecord) {
      load(lastDocRecord);
    }
  }, [canLoadNextPage, records, lastDocRecord, load]);

  return {
    records,
    loading,
    errorMessage,
    load,
    canLoadNextPage,
    loadNextPage,
  };
}
