import { ReferralLandgDocuments } from '@packages/core-shared';
import { useMemo } from 'react';
import { ReferralsApiRepository } from '../../../../../api';
import LatestClinicalReportRow from './LatestClinicalReportRow';
import styles from './LatestClinicalReportTable.module.css';
import { useFirebase } from '../../../../../context';

export default function LatestClinicalReportTable({
  referralId,
  latestClinicalReport,
  readonly,
}: {
  referralId: string;
  latestClinicalReport?: ReferralLandgDocuments['latestClinicalReport'];
  readonly: boolean;
}) {
  const { auth } = useFirebase();

  const referralsApiRepository = useMemo(
    () => new ReferralsApiRepository(auth),
    [auth],
  );

  if (!latestClinicalReport) {
    return <div></div>;
  }

  return (
    <table className={styles.table}>
      <thead>
        <tr style={{ textAlign: 'left' }}>
          <th>File</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <LatestClinicalReportRow
          referralId={referralId}
          referralsApiRepository={referralsApiRepository}
          latestClinicalReport={latestClinicalReport}
          readonly={readonly}
        />
      </tbody>
    </table>
  );
}
