import {
  Appointment,
  AppointmentStatus,
  InterimCareSummarySupportProgress,
  InterimCareSummarySupportProgressListSchema,
  InterimCareSummarySupportStatus,
  ServiceInfo,
  dateToLondonNumericDateString,
} from '@packages/core-shared';

import styles from './CreateInterimCareSummarySupportProgress.module.css';
import { AlertError, TextArea } from '@percihealth/react';
import { ValidationError } from 'yup';
import { useEffect, useState } from 'react';

interface Props {
  values: InterimCareSummarySupportProgress[];
  onChange: (arr: InterimCareSummarySupportProgress[]) => void;
}

export default function CreateInterimCareSummarySupportProgress({
  values,
  onChange,
}: Props) {
  const [validationsErrors, setValidationErrors] = useState<string | null>(
    null,
  );

  // Bug in formik, it does not show formik.errors for arrays
  // https://github.com/jaredpalmer/formik/issues/1116
  // Use yup validation
  useEffect(() => {
    try {
      InterimCareSummarySupportProgressListSchema.validateSync(values);
      setValidationErrors(null);
    } catch (err: unknown) {
      const validationError = err as ValidationError;
      const errors = validationError.errors
        // unique
        .filter((value, index, array) => array.indexOf(value) === index);
      setValidationErrors(errors.join(', '));
    }
  }, [values]);

  const remove = (index: number) => {
    onChange(values.filter((_, idx) => idx !== index));
  };

  return (
    <div className="full-width">
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Support type</th>
            <th>Session date</th>
            <th>Status</th>
            <th>Progress udpate</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {values.map((sp, index) => (
            <tr key={index}>
              <td>{sp.service.name}</td>
              <td>
                {sp.appointments.map((app) => (
                  <div key={app.id} className={styles['appointment-item']}>
                    {dateToLondonNumericDateString(app.date)}
                  </div>
                ))}
              </td>
              <td>
                {sp.appointments.map((app) => (
                  <div key={app.id} className={styles['appointment-item']}>
                    {app.status}
                  </div>
                ))}
              </td>
              <td>
                <TextArea
                  value={sp.progress}
                  rows={6}
                  onChange={(e) => {
                    const arr = [...values];
                    arr[index] = { ...arr[index], progress: e.target.value };
                    onChange(arr);
                  }}
                />
              </td>
              <td>
                <a onClick={() => remove(index)}>Delete</a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {validationsErrors && (
        <div style={{ marginTop: 24 }}>
          <AlertError>{validationsErrors}</AlertError>
        </div>
      )}
    </div>
  );
}

export const populateSupportProgress = (
  savedProgress: InterimCareSummarySupportProgress[],
  services: Record<string, ServiceInfo>,
  serviceIdAppointmentsMap: Record<string, Appointment[]>,
) => {
  const getStatus = (app: Appointment) => {
    if (app.status === AppointmentStatus.completed && app.noShow) {
      return InterimCareSummarySupportStatus.missed;
    }

    switch (app.status) {
      case AppointmentStatus.booked:
      case AppointmentStatus.rescheduled:
        return InterimCareSummarySupportStatus.booked;
      case AppointmentStatus.cancelled:
        return InterimCareSummarySupportStatus.cancelled;
      case AppointmentStatus.completed:
        return InterimCareSummarySupportStatus.attended;
      default:
        return InterimCareSummarySupportStatus.unknown;
    }
  };

  const results: InterimCareSummarySupportProgress[] = Object.keys(
    serviceIdAppointmentsMap,
  ).map((serviceId) => {
    const appointments = serviceIdAppointmentsMap[serviceId] ?? [];

    return {
      service: {
        id: serviceId,
        name: services[serviceId]?.name,
        professionalType: services[serviceId]?.professional_type,
      },
      progress:
        savedProgress.find((sp) => sp.service?.id === serviceId)?.progress ??
        '',
      appointments: appointments.map((app) => ({
        id: app.id,
        date: app.date,
        status: getStatus(app),
      })),
    };
  });

  return results;
};
