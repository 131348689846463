import {
  EbpMedicalHistoryPatientAnalyzeResult,
  ReferralLandgDocuments,
} from '@packages/core-shared';
import { useEvent } from '@packages/web-shared/hooks/eventEmitter/useEvent';
import { useEventDispatch } from '@packages/web-shared/hooks/eventEmitter/useEventDispatch';
import { Spinner } from '@percihealth/react';
import { useCallback, useMemo, useReducer, useRef, useState } from 'react';
import { EbpMedicalHistoryField } from './EbpMedicalHistoryField';
import { MedicalHistoryLocateEvents } from './MedicalHistoryLocateEvents';
import PdfDocumentViewer from './PdfDocumentViewer';

export default function PdfTabs({
  referralId,
  referralDocuments,
  medicalHistoryAnalyzeResult,
}: {
  referralId: string;
  referralDocuments?: ReferralLandgDocuments;
  medicalHistoryAnalyzeResult?: EbpMedicalHistoryPatientAnalyzeResult | null;
}) {
  const divRef = useRef<HTMLDivElement>(null);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const [activeTab, setActiveTab] = useState(0);

  const dispatchEvent = useEventDispatch();

  const containerWidth = useMemo(
    () => divRef.current?.clientWidth,
    [divRef.current?.clientWidth],
  );

  const dispatchLocateBlockEvent = useCallback(
    (payload: any) => {
      dispatchEvent(
        MedicalHistoryLocateEvents.ValidationLocateBlockEvent,
        payload,
      );
    },
    [dispatchEvent],
  );

  const pdfDocsData = useMemo(() => {
    if (!referralDocuments) return [];

    return [
      referralDocuments?.latestClinicalReport
        ? {
            title: 'Latest clinical report',
            docPath: referralDocuments?.latestClinicalReport?.path,
          }
        : undefined,
      ...(referralDocuments?.referrals ?? []).map((doc, _) => ({
        title: doc.name,
        docPath: doc.path,
      })),
    ].filter((page) => !!page) as {
      title: string;
      docPath: string;
    }[];
  }, [referralDocuments]);

  const handleLocateDocumentRequest = useCallback(
    (payload: { field: EbpMedicalHistoryField; docPath: string }) => {
      const newTabIndex = pdfDocsData.findIndex(
        (p) => p.docPath === payload.docPath,
      );
      if (newTabIndex >= 0) {
        setActiveTab(newTabIndex);
        // Important to force update the current section to send events through sub blocks
        forceUpdate();

        // give main thread priority to re-rendered
        setTimeout(() => {
          dispatchLocateBlockEvent(payload);
        }, 50);
      }
    },
    [pdfDocsData],
  );

  useEvent(
    MedicalHistoryLocateEvents.ValidationLocateDocumentEvent,
    handleLocateDocumentRequest,
  );

  const tabControls = useMemo(() => {
    return pdfDocsData.map((pdfDoc) => (
      <div
        key={pdfDoc.docPath}
        style={{
          cursor: 'pointer',
          background:
            activeTab === pdfDocsData.indexOf(pdfDoc)
              ? 'var(--global-color-primary-steel-teal)'
              : 'var(--global-color-primary-mint)',
          color:
            activeTab === pdfDocsData.indexOf(pdfDoc) ? 'white' : undefined,
          border: '1px solid grey',
          borderRadius: '12px',
          padding: '4px 12px',
        }}
        onClick={() => setActiveTab(pdfDocsData.indexOf(pdfDoc))}
      >
        {pdfDoc.title}
      </div>
    ));
  }, [pdfDocsData, activeTab]);

  const pdfControls = useMemo(() => {
    if (!containerWidth) return <Spinner />;

    return pdfDocsData.map((pdfDoc, index) => (
      <PdfDocumentViewer
        key={pdfDoc.docPath}
        referralId={referralId}
        visible={index === activeTab}
        docPath={pdfDoc.docPath}
        containerWidth={containerWidth}
        medicalHistoryAnalyzeResult={medicalHistoryAnalyzeResult}
      />
    ));
  }, [pdfDocsData, containerWidth, medicalHistoryAnalyzeResult, activeTab]);

  return (
    <div ref={divRef} style={{ overflow: 'hidden' }}>
      <div style={{ display: 'flex', gap: '4px 12px', marginBottom: '12px' }}>
        {tabControls}
      </div>
      <div style={{ height: '100%', paddingBottom: '64px' }}>
        {/* Load all documents to reduce switch time */}
        {pdfControls}
      </div>
    </div>
  );
}
