import PcpConcern from './PcpConcern';
import PcpConcernAdd from './PcpConcernAdd';
import styles from './index.module.css';

interface Props {
  concerns: string[];
  onChange: (arr: string[]) => void;
}

export default function PcpConcernList({ concerns, onChange }: Props) {
  return (
    <div className={styles.container}>
      <div className={styles.list}>
        {concerns.map((concern) => (
          <PcpConcern
            key={concern}
            concern={concern}
            onDelete={() => {
              onChange(concerns.filter((c) => c !== concern));
            }}
          />
        ))}
      </div>
      <PcpConcernAdd
        onAdd={(concern) => {
          // remove duplicates and sort
          const newArr = [...concerns.filter((c) => c !== concern), concern];
          onChange(newArr);
        }}
      />
    </div>
  );
}
