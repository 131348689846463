import {
  CareDocumentsApiRepository,
  useSubscribePatientAppointmentsWithTranscriptions,
} from '@packages/web-shared/api';
import {
  AlertError,
  Button,
  Modal,
  Spinner,
  Tooltip,
} from '@percihealth/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  PcpAiGenerated,
  PcpAiGeneratedResponse,
  PcpData,
  dateToLondonNumericDateString,
} from '@packages/core-shared';
import styles from './PcpFormGenerateByAiButton.module.css';
import QuestionIcon from '@packages/web-shared/components/icons/QuestionIcon';
import { AppointmentsTranscriptionsList } from '../components/AppointmentsTranscriptionsList';
import richTextHtml from '../components/richTextHtml';
import { useFirebase } from '@packages/web-shared';
import { DocumentsUsedList } from '../components/DocumentsUsedList';

interface Props {
  caseId: string;
  currentForm: PcpData;
  onChange: (data: PcpAiGenerated) => void;
}

export const PcpFormGenerateByAiButton = ({
  caseId,
  currentForm,
  onChange,
}: Props) => {
  const { auth } = useFirebase();

  const [openModal, setOpenModal] = useState(false);
  useEffect(() => {
    setOpenModal(false);
    setAiFormResponse(null);
    setErrorMessage(null);
  }, [caseId]);

  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [aiFormResponse, setAiFormResponse] =
    useState<PcpAiGeneratedResponse | null>(null);

  const docsApiRepository = useMemo(
    () => new CareDocumentsApiRepository(auth),
    [auth],
  );

  const appointmentsSubscriber =
    useSubscribePatientAppointmentsWithTranscriptions({
      patientEmail: caseId,
    });

  const onFillButtonClick = useCallback(async () => {
    setSubmitting(true);
    setErrorMessage(null);
    try {
      const result = await docsApiRepository.generateAiPcpForm(caseId);
      setAiFormResponse(result);
      setOpenModal(true);
    } catch (err) {
      console.log(err);
      setErrorMessage((err as Error).message);
    } finally {
      setSubmitting(false);
    }
  }, [docsApiRepository]);

  const formatPatientService = useCallback(
    (ps: {
      service: {
        id: string;
        name: string;
        healee_ref: string;
      };
      concerns?: string[] | undefined;
    }) => {
      return `- ${ps.service.name} ${ps.concerns?.length ? ` - ${ps.concerns?.join(', ') ?? ''}` : ''}`;
    },
    [],
  );

  const isSameFormData = useMemo(() => {
    const aiForm = aiFormResponse?.form;
    if (!aiForm) {
      return false;
    }

    return (
      aiForm.patient?.iam === currentForm.patient.iam &&
      aiForm.dateOfConsultation === currentForm.dateOfConsultation &&
      aiForm.notes === currentForm.notes &&
      JSON.stringify(aiForm?.patientServices?.map(formatPatientService)) ===
        JSON.stringify(currentForm.patientServices?.map(formatPatientService))
    );
  }, [aiFormResponse, currentForm, formatPatientService]);

  const closeModal = useCallback(() => {
    setOpenModal(false);
    setAiFormResponse(null);
    setErrorMessage(null);
  }, []);

  if (appointmentsSubscriber.loading) {
    return <Spinner />;
  }

  return (
    <div className={styles.container}>
      <div className={styles['btn-container']}>
        <Button
          submitting={submitting}
          disabled={appointmentsSubscriber.records.length === 0}
          onClick={onFillButtonClick}
        >
          Fill by AI
        </Button>
        {!appointmentsSubscriber.errorMessage &&
          appointmentsSubscriber.records.length === 0 && (
            <Tooltip
              trigger={
                <div>
                  <QuestionIcon />
                </div>
              }
            >
              <div>Appointment transcription is not created yet.</div>
            </Tooltip>
          )}
      </div>

      <div>
        {appointmentsSubscriber.errorMessage && (
          <AlertError>{appointmentsSubscriber.errorMessage}</AlertError>
        )}
      </div>
      <div>{errorMessage && <AlertError>{errorMessage}</AlertError>}</div>

      <Modal open={openModal} onClose={closeModal} center>
        <h3>AI generated PCP form</h3>

        <AppointmentsTranscriptionsList
          appointments={aiFormResponse?.appointmentsWithTranscriptions ?? []}
        />

        <DocumentsUsedList documents={aiFormResponse?.documentsUsed ?? []} />

        <table className={styles.table}>
          <thead>
            <tr>
              <th></th>
              <th>Current form</th>
              <th>Incoming AI data</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>I am:</td>
              <td>{currentForm.patient.iam}</td>
              <td>{aiFormResponse?.form.patient?.iam}</td>
            </tr>

            <tr>
              <td>Date of consultation:</td>
              <td>
                {dateToLondonNumericDateString(currentForm.dateOfConsultation)}
              </td>
              <td>
                {dateToLondonNumericDateString(
                  aiFormResponse?.form?.dateOfConsultation ?? undefined,
                )}
              </td>
            </tr>

            <tr>
              <td>Patient services:</td>
              <td>
                {currentForm?.patientServices?.map((ps) => (
                  <div key={formatPatientService(ps)}>
                    {formatPatientService(ps)}
                  </div>
                ))}
              </td>
              <td>
                {aiFormResponse?.form?.patientServices?.map((ps) => (
                  <div key={formatPatientService(ps)}>
                    {formatPatientService(ps)}
                  </div>
                ))}
              </td>
            </tr>

            <tr>
              <td>Notes:</td>
              <td>{richTextHtml(currentForm?.notes)}</td>
              <td>{richTextHtml(aiFormResponse?.form?.notes ?? '')}</td>
            </tr>
          </tbody>
        </table>
        {aiFormResponse && !isSameFormData && (
          <div>
            <p>Import AI data to the current form?</p>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '16px',
              }}
            >
              <Button onClick={closeModal}>Cancel</Button>
              <Button
                onClick={() => {
                  onChange(aiFormResponse?.form);
                  closeModal();
                }}
              >
                Proceed
              </Button>
            </div>
          </div>
        )}
        {isSameFormData && (
          <div>
            <p>AI data has not difference with the current form.</p>
            <Button onClick={closeModal}>Close</Button>
          </div>
        )}
      </Modal>
    </div>
  );
};
