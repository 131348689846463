import { IObject, normalizeDocs } from '@packages/core-shared';
import { DocumentData, getDocs, limit, Query, query } from 'firebase/firestore';
import { useEffect, useMemo, useState } from 'react';

export function useLoadLimitedFirestoreList<
  TDoc extends IObject.HasId<unknown> | IObject.Timestamped<unknown>,
  TResult extends TDoc = TDoc,
>(
  q: Query<DocumentData>,
  maxDocuments = Number(process.env.REACT_APP_DEFAULT_QUERY_LIMIT ?? 500),
  map?: (doc: TDoc) => TResult,
) {
  const [records, setRecords] = useState<TResult[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    q = query(q, limit(maxDocuments));

    getDocs(q).then(
      (snapshot) => {
        let rows = normalizeDocs<TResult>(snapshot.docs);
        setErrorMessage(null);
        setLoading(false);
        setRecords(map ? rows.map(map) : rows);
      },
      (err) => {
        console.error(err);
        setLoading(false);
        setErrorMessage(JSON.stringify(err));
      },
    );
  }, [q, maxDocuments, map]);

  const result = useMemo(
    () => ({ records, loading, errorMessage }),
    [records, loading, errorMessage],
  );

  return result;
}
