import { EbpCaseEmail, EmailEvent, normalizeDocs } from '@packages/core-shared';
import { collection, getDocs, orderBy, query, where } from 'firebase/firestore';
import { useSubscribeFirestoreList } from '../../hooks/useSubscribeFirestoreList';
import { useEffect, useMemo, useState } from 'react';
import { useFirebase } from '../../context';

export const useLoadCaseEmails = (caseId: string, template?: string) => {
  const { firestore } = useFirebase();

  const [loadingEvents, setLoadingEvents] = useState(false);
  const [errorEvents, setErrorEvents] = useState<string | null>();
  const [recordsWithEvents, setRecordsWithEvents] = useState<EbpCaseEmail[]>(
    [],
  );

  const queryResult = useMemo(() => {
    let q = query(collection(firestore, `emails`));
    q = query(
      q,
      where('caseId', '==', caseId ?? '__UNKNOWN_PATIENT__'),
      orderBy('createdAt', 'desc'),
    );

    if (template) {
      q = query(q, where('template', '==', template));
    }
    return q;
  }, [firestore, caseId, template]);

  const { records, loading, errorMessage } =
    useSubscribeFirestoreList<EbpCaseEmail>(queryResult);

  useEffect(() => {
    const loadFirestoreList = async () => {
      const results: EbpCaseEmail[] = [];
      try {
        setLoadingEvents(true);
        setErrorEvents(null);

        for (const email of records) {
          const qe = query(
            collection(firestore, `emails/${email.id}/events`),
            orderBy('createdAt', 'asc'),
          );

          const events = await getDocs(qe);
          results.push({
            ...email,
            events: normalizeDocs<EmailEvent>(events.docs),
          });
        }

        setRecordsWithEvents(results);
      } catch (error) {
        console.error(error);
        setErrorEvents('Failed to load email events');
      } finally {
        setLoadingEvents(false);
      }
    };
    loadFirestoreList();
  }, [firestore, records]);

  return {
    records: recordsWithEvents,
    loading: loading || loadingEvents,
    errorMessage: errorMessage ?? errorEvents,
  };
};
