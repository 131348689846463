import { useAuth } from '@percihealth/react-auth';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

export default function LandgRequestPreauthorizationFormLink({
  patientFullname,
  careLevel,
  gipCode,
  referrerEmail,
}: {
  patientFullname: string;
  careLevel?: string;
  gipCode?: string;
  referrerEmail?: string;
}) {
  const { user } = useAuth();

  const link = useMemo(() => {
    const searchParams = new URLSearchParams();

    searchParams.append(
      process.env.REACT_APP_PREAUTHORIZATION_FORM_FIELD_MEMBER_NAME!,
      patientFullname,
    );

    if (user?.email) {
      searchParams.append(
        process.env.REACT_APP_PREAUTHORIZATION_FORM_FIELD_NURSE_EMAIL!,
        user.email,
      );
    }

    if (gipCode) {
      searchParams.append(
        process.env.REACT_APP_PREAUTHORIZATION_FORM_FIELD_GIP_CODE!,
        gipCode,
      );
    }

    if (careLevel) {
      searchParams.append(
        process.env.REACT_APP_PREAUTHORIZATION_FORM_FIELD_CARE_LEVEL!,
        careLevel,
      );
    }

    if (referrerEmail) {
      searchParams.append(
        process.env.REACT_APP_PREAUTHORIZATION_FORM_FIELD_REFERRER_EMAIL!,
        referrerEmail,
      );
    }

    return `${process.env.REACT_APP_PREAUTHORIZATION_FORM_URL}?${searchParams}`;
  }, [
    process.env.REACT_APP_PREAUTHORIZATION_FORM_URL,
    process.env.REACT_APP_PREAUTHORIZATION_FORM_FIELD_NURSE_EMAIL,
    process.env.REACT_APP_PREAUTHORIZATION_FORM_FIELD_GIP_CODE,
    process.env.REACT_APP_PREAUTHORIZATION_FORM_FIELD_MEMBER_NAME,
    process.env.REACT_APP_PREAUTHORIZATION_FORM_FIELD_CARE_LEVEL,
    process.env.REACT_APP_PREAUTHORIZATION_FORM_FIELD_REFERRER_EMAIL,
    user?.email,
    patientFullname,
    careLevel,
    gipCode,
    referrerEmail,
  ]);
  return (
    <Link to={link} target="_blank" rel="noopener noreferrer">
      Request pre-authorisation
    </Link>
  );
}
