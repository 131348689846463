import { ApiRepository } from '../../helpers/ApiRepository';

export class ReferralsApiRepository extends ApiRepository {
  async generateDownloadUrl({
    referralId,
    filePath,
  }: {
    referralId: string;
    filePath: string;
  }) {
    const token = await this.getToken();
    if (!token) {
      throw new Error('Unauthorized');
    }

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/referrals/${referralId}/storage/url`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({ path: filePath }),
      },
    );

    if (!response.ok) {
      throw new Error(`Failed to generate download url for ${filePath}`);
    }

    const json = await response.json();

    return json?.url;
  }

  async uploadLatestClinicalReportToHealee({
    referralId,
  }: {
    referralId: string;
  }) {
    const token = await this.getToken();
    if (!token) {
      throw new Error('Unauthorized');
    }

    return await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/referrals/${referralId}/documents/latestClinicalReport/healee`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({}),
      },
    );
  }

  async uploadReferralFormToHealee({
    referralId,
    fileName,
  }: {
    referralId: string;
    fileName: string;
  }) {
    const token = await this.getToken();
    if (!token) {
      throw new Error('Unauthorized');
    }

    return await fetch(
      `${
        process.env.REACT_APP_BACKEND_URL
      }/api/referrals/${referralId}/documents/referrals/${encodeURIComponent(
        fileName,
      )}/healee`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({}),
      },
    );
  }
}
