import { AppointmentStatus } from '@packages/core-shared';
import { useLoadPatientAppointments } from '@packages/web-shared/api';
import { useMemo } from 'react';
import { CancerNurseSpecialistServiceId } from '@packages/core-shared';

export const useGetFirstAppointmentWithCns = (patientEmail?: string) => {
  const appointmentsLoader = useLoadPatientAppointments({
    patientEmail,
  });

  const firstAppointmentWithCns = useMemo(() => {
    return appointmentsLoader.records.find(
      (app) =>
        app.status !== AppointmentStatus.cancelled &&
        app.status !== AppointmentStatus.rescheduled &&
        app.services?.includes(CancerNurseSpecialistServiceId),
    );
  }, [appointmentsLoader.records]);

  return firstAppointmentWithCns;
};
