import {
  AppointmentConclusion,
  dateTimeToLondonDateTimeString,
  hasConclusionServices,
  ServiceInfo,
} from '@packages/core-shared';
import { AppointmentReferralReviewStatus } from '@packages/core-shared';
import {
  AlertError,
  Button,
  DropdownSearch,
  TextArea,
} from '@percihealth/react';
import { useMemo, useState } from 'react';
import { AppointmentApiRepository } from '../../../api';
import styles from './AppointmentConclusionControl.module.css';
import { useFirebase } from '../../../context';

interface Props {
  appointmentId: string;
  services: Record<string, ServiceInfo>;
  conclusion: AppointmentConclusion;
  disabled: boolean;
}

export default function AppointmentConclusionControl({
  appointmentId,
  services,
  conclusion,
  disabled,
}: Props) {
  const { auth } = useFirebase();

  const appointmentApiRepository = useMemo(
    () => new AppointmentApiRepository(auth),
    [auth],
  );

  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [value, setValue] = useState<
    AppointmentReferralReviewStatus | undefined
  >(conclusion.requestedReferral?.review?.status);

  const [notes, setNotes] = useState<string>(
    conclusion.requestedReferral?.review?.notes ?? '',
  );

  const [readonly, setReadonly] = useState(
    !!conclusion.requestedReferral?.review?.status,
  );

  // If services are empty, then notes are not displayed in Healee.
  // A nurse will not be able to make any action.
  // Below we will hide the review section if there are no services.
  const hasServices = useMemo(
    () => hasConclusionServices(conclusion),
    [conclusion],
  );

  return (
    <div className={styles.referral}>
      <div>
        Date: <i>{dateTimeToLondonDateTimeString(conclusion.date)}</i>
        <br />
        {conclusion.requestedReferral && (
          <>
            Followed up required:{' '}
            <i>
              {conclusion.requestedReferral.followUpRequired ? 'Yes' : 'No'}
            </i>
            <br />
            Within time period:{' '}
            <i>{conclusion.requestedReferral.withinWeeks}</i>
            <br />
            {hasServices && (
              <>
                Services:{' '}
                <i>
                  {(conclusion.requestedReferral.services ?? [])
                    .map(
                      (serviceId) =>
                        services[serviceId?.toString() ?? '']?.name ?? '?',
                    )
                    .join(', ')}
                </i>
                <br />
                Notes: <i>{conclusion.requestedReferral.notes}</i>
              </>
            )}
          </>
        )}
      </div>
      {hasServices && (
        <>
          <hr style={{ borderColor: '#7d7d7d' }} />
          <div className={styles.review}>
            <div className={styles['review-dropdown-container']}>
              <div className={styles['review-dropdown']}>
                <DropdownSearch
                  label="Referrals"
                  required
                  disabled={disabled || readonly || submitting}
                  multiple={false}
                  value={value ?? '-'}
                  options={[
                    '-',
                    ...Object.values(AppointmentReferralReviewStatus),
                  ].map((o) => ({
                    value: o,
                    name: o,
                  }))}
                  onChange={(v) => {
                    setValue(
                      v === '-'
                        ? undefined
                        : (v as AppointmentReferralReviewStatus),
                    );
                  }}
                />
              </div>
              <a
                onClick={() => {
                  setReadonly((prev) => !prev);
                }}
              >
                {readonly ? 'Edit' : 'Cancel'}
              </a>
            </div>
            <TextArea
              label="Notes"
              required
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              disabled={disabled || readonly || submitting}
            />
            <Button
              disabled={disabled || readonly}
              submitting={submitting}
              onClick={async () => {
                if (!value) {
                  setErrorMessage('Please select the referral status');
                  return;
                }

                if (!notes) {
                  setErrorMessage('Please provide notes');
                  return;
                }

                setErrorMessage(null);
                setSubmitting(true);
                try {
                  await appointmentApiRepository.updateReferralReview(
                    appointmentId,
                    {
                      conclusion: {
                        requestedReferral: { review: { status: value, notes } },
                      },
                    },
                  );
                  setReadonly(true);
                } catch (err) {
                  console.error(err);
                } finally {
                  setSubmitting(false);
                }
              }}
            >
              Save
            </Button>
          </div>
        </>
      )}
      {errorMessage && <AlertError>{errorMessage}</AlertError>}
    </div>
  );
}
