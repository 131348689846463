import { AlertError, Button, Modal } from '@percihealth/react';
import { useEffect, useMemo, useState } from 'react';
import { CareDocumentsApiRepository } from '../../../../../api';
import { useFirebase } from '../../../../../context';

interface Props {
  caseId: string;
  documentId: string | null;
  onClose: (uploaded: boolean) => void;
}

export const DocumentToHealeeModal = ({
  caseId,
  documentId,
  onClose,
}: Props) => {
  const { auth } = useFirebase();

  const docApiRepository = useMemo(
    () => new CareDocumentsApiRepository(auth),
    [auth],
  );
  const [isSending, setIsSending] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    setErrorMessage(null);
  }, [documentId]);

  const uploadDocument = async () => {
    if (!documentId) {
      return;
    }

    try {
      setIsSending(true);
      setErrorMessage(null);
      await docApiRepository.uploadDocumentToHealee({
        caseId,
        documentId,
      });
      onClose(true);
    } catch (err) {
      console.error(err);
      setErrorMessage(err instanceof Error ? err.message : JSON.stringify(err));
    } finally {
      setIsSending(false);
    }
  };

  return (
    <Modal open={!!documentId} onClose={() => onClose(false)} center>
      <h3>Healee MD - upload confirmation</h3>
      <p>
        You are going to upload the document to Healee MD.
        <br />
        Proceed?
        <br />
      </p>
      {errorMessage && <AlertError>{errorMessage}</AlertError>}
      <br />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button onClick={() => onClose(false)} disabled={isSending}>
          Cancel
        </Button>

        <Button
          level="secondary"
          submitting={isSending}
          onClick={uploadDocument}
        >
          Send
        </Button>
      </div>
    </Modal>
  );
};
