import { default as svg } from './logo.svg';
import { Link } from 'react-router-dom';
import styles from './Header.module.css';
import SignedInHeaderSection from '@packages/web-shared/components/cases/Layout/SignedInHeaderSection';
import { classList } from '@percihealth/react';

export default function Header() {
  return (
    <header className={classList('grid-outer', styles.header)}>
      <img src={svg} className={styles.img} />
      <nav className={styles.nav}>
        <Link to="/">Cases</Link>
      </nav>
      <div className={styles['right-block']}>
        <SignedInHeaderSection />
      </div>
    </header>
  );
}
