import Footer from './Footer/Footer';
import styles from './MainLayout.module.css';
import { classList } from '@percihealth/react';

export default function MainLayout(props: {
  header: React.ReactNode;
  children: React.ReactNode;
  containerStyle?: React.CSSProperties;
  mainStyle?: React.CSSProperties;
}) {
  return (
    <div className={styles.layout} style={props.containerStyle}>
      {props.header}
      <main
        className={classList('grid-outer', styles.main)}
        style={props.mainStyle}
      >
        {props.children}
      </main>
      <Footer />
    </div>
  );
}
