import { ServiceInfo } from '@packages/core-shared';
import { useEffect, useState } from 'react';

export const useLoadServices = () => {
  const [services, setServices] = useState<Record<string, ServiceInfo>>({});
  const [servicesError, setServiceError] = useState<string | null>(null);
  const [servicesLoading, setServicesLoading] = useState(false);

  useEffect(() => {
    const load = async () => {
      // Get Services from Referrals backend
      try {
        setServicesLoading(true);
        setServiceError(null);
        const resp = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/services`,
          {
            method: 'GET',
            headers: {
              Accept: 'application/json',
            },
          },
        );

        const data = resp.ok ? await resp.json() : {};
        setServices(data ?? {});
      } catch (err) {
        setServiceError(JSON.stringify(err));
      } finally {
        setServicesLoading(false);
      }
    };
    load();
  }, []);

  return { services, servicesError, servicesLoading };
};
