import { useCallback, useMemo, useReducer, useRef } from 'react';
import { EventContext } from './EventContext';

export const EventEmitter = ({ children }: any) => {
  const [subscribers, dispatch] = useReducer(
    (
      state: any,
      action: { type: 'subscribe' | 'unsubscribe'; event: any; callback: any },
    ) => {
      const { type, event } = action;
      switch (type) {
        case 'subscribe': {
          const { callback } = action;
          if (event in state) {
            if (state[event].includes(callback)) {
              return state;
            }
            return { ...state, [event]: [...state[event], callback] };
          }
          return { ...state, [event]: [callback] };
        }

        case 'unsubscribe': {
          const { callback } = action;
          if (event in state && state[event].includes(callback)) {
            return {
              ...state,
              [event]: [...state[event].filter((cb: any) => cb !== callback)],
            };
          }
          return state;
        }

        default:
          throw new Error();
      }
    },
    {},
    () => ({}),
  );

  const subscribersRef = useRef<any>({});

  subscribersRef.current = useMemo(() => subscribers, [subscribers]);

  const subscribe = useCallback(
    (event: any, callback: any) => {
      dispatch({ type: 'subscribe', event, callback });
    },
    [dispatch],
  );

  const unsubscribe = useCallback(
    (event: any, callback: any) => {
      dispatch({ type: 'unsubscribe', event, callback });
    },
    [dispatch],
  );

  const dispatchEvent = useCallback(
    (event: any, payload: any) => {
      if (event in subscribersRef?.current) {
        subscribersRef?.current[event].forEach((cb: any) => cb(payload));
      }
    },
    [subscribersRef],
  );

  const eventPack = useMemo(
    () => [subscribe, unsubscribe, dispatchEvent],
    [subscribe, unsubscribe, dispatchEvent],
  );

  return (
    <EventContext.Provider value={eventPack}>{children}</EventContext.Provider>
  );
};
