import { AppointmentReferralReview } from '@packages/core-shared';
import { ApiRepository } from '../../helpers/ApiRepository';

export class AppointmentApiRepository extends ApiRepository {
  async updateFollowUp(id: string, followedUp: boolean) {
    const token = await this.getToken();
    if (!token) {
      throw new Error('Unauthorized');
    }

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/appointments/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        method: 'PUT',
        body: JSON.stringify({ followedUp }),
      },
    );

    if (!response.ok) {
      throw new Error('Error updating follow up');
    }
  }

  async updateReferralReview(
    id: string,
    data: {
      conclusion: { requestedReferral: { review: AppointmentReferralReview } };
    },
  ) {
    const token = await this.getToken();
    if (!token) {
      throw new Error('Unauthorized');
    }

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/api/appointments/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        method: 'PUT',
        body: JSON.stringify(data),
      },
    );

    if (!response.ok) {
      throw new Error('Error updating referral review');
    }
  }
}
