import { classList } from '@percihealth/react';
import { PropsWithChildren, useState } from 'react';
import { default as DownArrowSvg } from './downarrow.svg';
import { default as UpArrowSvg } from './uparrow.svg';
import styles from './CollapsibleContainer.module.scss';

interface Props {
  name: string | React.ReactNode;
  defaultCollapsed?: boolean;
}

export default function CollapsibleContainer({
  name,
  defaultCollapsed = true,
  children,
}: PropsWithChildren<Props>) {
  const [isCollapsed, setIsCollapsed] = useState(defaultCollapsed);

  return (
    <div>
      <div
        className={styles.header}
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        {name}{' '}
        <img
          className={styles.arrow}
          src={isCollapsed ? DownArrowSvg : UpArrowSvg}
        />
      </div>
      <div
        className={classList(
          styles.content,
          isCollapsed ? styles['content-collapsed'] : undefined,
        )}
      >
        {children}
      </div>
    </div>
  );
}
