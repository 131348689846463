import {
  dateTimeToLocalDateTimeString,
  ReferralLandgDocuments,
} from '@packages/core-shared';
import { AlertError, Button } from '@percihealth/react';
import { useCallback, useState } from 'react';
import { ReferralsApiRepository } from '../../../../../api';
import { ReferralDownloadFileLink } from '../Referrals/ReferralDownloadFileLink';

export default function LatestClinicalReportRow({
  referralsApiRepository,
  referralId,
  latestClinicalReport,
  readonly,
}: {
  referralsApiRepository: ReferralsApiRepository;
  referralId: string;
  latestClinicalReport: ReferralLandgDocuments['latestClinicalReport'];
  readonly: boolean;
}) {
  const [isUploading, setIsUploading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const uploadToHealee = useCallback(async () => {
    try {
      setIsUploading(true);
      setErrorMessage(null);
      const resp =
        await referralsApiRepository.uploadLatestClinicalReportToHealee({
          referralId,
        });

      if (!resp.ok) {
        try {
          const json = await resp.json();
          setErrorMessage(json.errors?.join(', ') ?? 'Internal error');
        } catch {
          setErrorMessage(resp.statusText);
        }
      }
    } catch (err) {
      console.log(err);
      setErrorMessage((err as Error).message);
    } finally {
      setIsUploading(false);
    }
  }, [referralId, latestClinicalReport.name, referralsApiRepository]);

  return (
    <tr>
      <td>
        <ReferralDownloadFileLink
          key={`${latestClinicalReport.path}/${latestClinicalReport.name}`}
          referralId={referralId}
          fileName={latestClinicalReport.name}
          filePath={latestClinicalReport.path}
        />
      </td>
      <td>
        {latestClinicalReport.uploadedToHealee ? (
          <div>
            Uploaded at
            <br />
            {dateTimeToLocalDateTimeString(
              latestClinicalReport.uploadedToHealee.at,
            )}
          </div>
        ) : (
          <Button
            disabled={readonly}
            submitting={isUploading}
            onClick={uploadToHealee}
          >
            Share&nbsp;with&nbsp;MDT
          </Button>
        )}
        {errorMessage && (
          <div>
            <AlertError>{errorMessage}</AlertError>
          </div>
        )}
      </td>
    </tr>
  );
}
