import { IObject, normalizeDocs } from '@packages/core-shared';
import {
  DocumentData,
  limit,
  onSnapshot,
  Query,
  query,
} from 'firebase/firestore';
import { useEffect, useState } from 'react';

export function useSubscribeFirestoreList<
  TDoc extends IObject.Timestamped<unknown>,
  TResult extends TDoc = TDoc,
>(q: Query<DocumentData>, maxDocuments = 100, map?: (doc: TDoc) => TResult) {
  const [records, setRecords] = useState<TResult[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const queryResult = query(q, limit(maxDocuments));
    const unsubscribe = onSnapshot(
      queryResult,
      (snapshot) => {
        let rows = normalizeDocs<TResult>(snapshot.docs);
        setErrorMessage(null);
        setLoading(false);

        setRecords(() => {
          return map ? rows.map(map) : rows;
        });
      },
      (err) => {
        console.error(err);
        setLoading(false);
        setErrorMessage(JSON.stringify(err));
      },
    );
    return () => unsubscribe();
  }, [q, maxDocuments]);

  return {
    records,
    loading,
    errorMessage,
  };
}
