import { CSSProperties, MouseEventHandler } from 'react';

export default function SyncIcon({
  style,
  width,
  onClick,
}: {
  style?: CSSProperties;
  width?: string | number;
  onClick?: MouseEventHandler<SVGSVGElement> | undefined;
}) {
  return (
    <svg
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      width={width}
      onClick={onClick}
    >
      <path
        fill="currentColor"
        d="M116.9,221.48,34,138.58l82.9-82.9L138.19,77,91.63,123.53H344.31A123.57,123.57,0,0,1,467.75,247H437.64a93.43,93.43,0,0,0-93.33-93.33H91.63l46.56,46.56Zm278.2,69L373.81,311.8l46.56,46.56H167.69A93.43,93.43,0,0,1,74.36,265H44.25A123.57,123.57,0,0,0,167.69,388.47H420.37L373.81,435l21.29,21.29,82.9-82.9Z"
      />
    </svg>
  );
}
