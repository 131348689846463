import {
  MycawInitialForm,
  MycawFollowUpFieldRef,
  MycawInitialFormFieldRef,
} from '@packages/core-shared';

export const getInitialValue = (
  followUpRef: string,
  latestInitialForm: MycawInitialForm | null,
) => {
  if (!latestInitialForm) {
    return undefined;
  }

  const findInitialValueByRef = (ref: string) => {
    return Object.keys(latestInitialForm)
      .map((key) => latestInitialForm[key])
      .find((f) => f.ref === ref)?.value;
  };

  if (followUpRef === MycawFollowUpFieldRef.Concern1Level) {
    return findInitialValueByRef(MycawInitialFormFieldRef.Concern1Level);
  } else if (followUpRef === MycawFollowUpFieldRef.Concern2Level) {
    return findInitialValueByRef(MycawInitialFormFieldRef.Concern2Level);
  } else if (followUpRef === MycawFollowUpFieldRef.FeelingLevel) {
    return findInitialValueByRef(MycawInitialFormFieldRef.FeelingLevel);
  }

  return undefined;
};
