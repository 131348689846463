import { useEffect, useMemo, useState } from 'react';
import styles from './PcpServices.module.css';
import { useLoadProfessionals } from '@packages/web-shared/api';
import {
  AlertError,
  Button,
  DropdownSearch,
  Spinner,
} from '@percihealth/react';
import {
  Appointment,
  AppointmentStatus,
  PcpPatientService,
  PcpPatientServiceListSchema,
  RecommendedSupportStatus,
  ServiceInfo,
} from '@packages/core-shared';
import PcpConcernList from './PcpConcernList';
import _ from 'lodash';
import { ValidationError } from 'yup';

interface Props {
  values: PcpPatientService[];
  services: Record<string, ServiceInfo>;
  onChange: (arr: PcpPatientService[]) => void;
}

const EmptyOption = {
  value: '',
  name: '-',
};

export default function PcpServices({ values, services, onChange }: Props) {
  const [serviceKeyToAdd, setServiceKeyToAdd] = useState<string | null>(
    Object.keys(services).length > 0 ? Object.keys(services)[0] : null,
  );

  const [validationsErrors, setValidationErrors] = useState<string | null>(
    null,
  );

  // Bug in formik, it does not show formik.errors for arrays
  // https://github.com/jaredpalmer/formik/issues/1116
  // Use yup validation
  useEffect(() => {
    try {
      PcpPatientServiceListSchema.validateSync(values);
      setValidationErrors(null);
    } catch (err: unknown) {
      const validationError = err as ValidationError;
      const errors = validationError.errors
        // unique
        .filter((value, index, array) => array.indexOf(value) === index);
      setValidationErrors(errors.join(', '));
    }
  }, [values]);

  const serviceOptions = useMemo(() => {
    return Object.keys(services).map((key) => ({
      value: key,
      name: services[key].name,
    }));
  }, [services]);

  const professionalsLoader = useLoadProfessionals();

  const add = () => {
    if (!serviceKeyToAdd) {
      return;
    }

    const arr = [...values];
    arr.push({
      service: {
        ...services[serviceKeyToAdd],
        id: serviceKeyToAdd,
      },
      concerns: [],
      status: RecommendedSupportStatus.pending,
    });
    onChange(arr);
  };

  const remove = (index: number) => {
    onChange(values.filter((_, idx) => idx !== index));
  };

  return (
    <div className="full-width">
      {professionalsLoader.errorMessage && (
        <AlertError>{professionalsLoader.errorMessage}</AlertError>
      )}
      {professionalsLoader.loading && <Spinner className={styles.spinner} />}
      <p className="description">
        Based on your personal story and everything you have shared with your
        dedicated nurse, they have suggested that the following support types
        could be beneficial.
      </p>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Support type</th>
            <th>Concerns</th>
            <th>Referred to</th>
            <th>Status</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {values.map((ps, index) => (
            <tr key={index}>
              <td>{ps.service.name}</td>
              <td>
                <PcpConcernList
                  concerns={ps.concerns ?? []}
                  onChange={(concerns) => {
                    const arr = [...values];
                    arr[index] = { ...arr[index], concerns };
                    onChange(arr);
                  }}
                />
              </td>
              <td style={{ minWidth: 300 }}>
                {ps.appointmentId && ps.professional?.name ? (
                  ps.professional.name
                ) : (
                  <DropdownSearch
                    multiple={false}
                    autoComplete="on"
                    value={ps.professional?.id ?? ''}
                    options={[
                      EmptyOption,
                      ...professionalsLoader.records
                        .filter((prof) => prof.services.includes(ps.service.id))
                        .map((prof) => ({
                          value: prof.id,
                          name: prof.fullname,
                        })),
                    ]}
                    onChange={(val) => {
                      const professionalId = val as string;
                      ps.professional = {
                        id: professionalId,
                        name:
                          professionalsLoader.records.find(
                            (prof) => prof.id === professionalId,
                          )?.fullname ?? '',
                      };
                      onChange(values);
                    }}
                  />
                )}
              </td>
              <td>{ps.status}</td>
              <td>
                <a onClick={() => remove(index)}>Delete</a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className={styles['add-container']}>
        <div className={styles.services}>
          <DropdownSearch
            multiple={false}
            autoComplete="on"
            value={serviceKeyToAdd ?? ''}
            options={serviceOptions}
            onChange={(val) => {
              setServiceKeyToAdd(val as string);
            }}
          />
        </div>
        <Button type="button" className={styles.add} onClick={add}>
          Add
        </Button>
      </div>
      {validationsErrors && (
        <div style={{ marginTop: 24 }}>
          <AlertError>{validationsErrors}</AlertError>
        </div>
      )}
    </div>
  );
}

export const populatePatientServicesWithAppointnments = (
  savedPatientServices: PcpPatientService[],
  services: Record<string, ServiceInfo>,
  serviceIdAppointmentsMap: Record<string, Appointment[]>,
) => {
  // populate missed services
  const results = [...savedPatientServices];

  for (const serviceId in serviceIdAppointmentsMap) {
    if (!results.some((ps) => ps.service.id === serviceId)) {
      results.push({
        service: {
          id: serviceId,
          name: services[serviceId]?.name,
          healee_ref: services[serviceId]?.healee_ref,
        },
        concerns: [],
        status: RecommendedSupportStatus.pending,
      });
    }
  }

  // Collect statuses
  // Appointmetns are orderd in the desceneding order by "date"

  // Service 1 with the "booked" status
  //  - BOOKED 20 AUG
  //  - BOOKED 21 AUG
  // Result: take the first => 20 AUG

  // Service 1 with the "attended" status
  // - ATTENDED 1 JUL
  // - ATTENDED 2 JUL
  // Result: take the last => 2 JUL
  for (const profServiceRow of results) {
    const appointments =
      serviceIdAppointmentsMap[profServiceRow.service.id] ?? [];
    const appointment =
      appointments.findLast(
        (app) => app.status === AppointmentStatus.completed,
      ) ??
      appointments.find((app) =>
        [AppointmentStatus.booked, AppointmentStatus.rescheduled].includes(
          app.status,
        ),
      );

    if (appointment) {
      profServiceRow.professional = {
        name: appointment.doctor ?? '',
      };
      profServiceRow.appointmentId = appointment.id;
      profServiceRow.status =
        appointment.status === AppointmentStatus.completed
          ? RecommendedSupportStatus.attended
          : RecommendedSupportStatus.booked;
    } else {
      profServiceRow.appointmentId = undefined;
      profServiceRow.status = RecommendedSupportStatus.pending;
    }
  }

  return results;
};
