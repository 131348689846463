import { EbpTypeformType, MycawFollowUpForm } from '@packages/core-shared';
import { and, collection, orderBy, query, where } from 'firebase/firestore';
import { useMemo } from 'react';
import { useSubscribeFirestoreList } from '../../hooks/useSubscribeFirestoreList';
import { useFirebase } from '../../context';

export const useLoadMycawFollowUpForms = (patientEmail?: string) => {
  const { firestore } = useFirebase();

  const queryResult = useMemo(() => {
    let q = query(
      collection(firestore, 'forms'),
      and(
        where('hidden_email', '==', patientEmail ?? '__NON_EXISTING_EMAIL__'),
        where('formType', '==', EbpTypeformType.MycawFollowUp),
      ),
      orderBy('createdAt', 'desc'),
    );
    return q;
  }, [firestore, patientEmail]);

  const loadFirestoreList =
    useSubscribeFirestoreList<MycawFollowUpForm>(queryResult);
  return loadFirestoreList;
};
