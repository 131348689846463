import { CareDocumentClinicalAndExperienceOutcomes } from '@packages/core-shared';
import { ClinicalExperienceAndOutcomesItem } from './ClinicalExperienceAndOutcomesItem';

export default function ClinicalExperienceAndOutcomes({
  clinicalAndExperienceOutcomes,
}: {
  clinicalAndExperienceOutcomes: CareDocumentClinicalAndExperienceOutcomes;
}) {
  return (
    <>
      <ClinicalExperienceAndOutcomesItem
        name="MYCaW CONCERN 1"
        formikId="clinicalAndExperienceOutcomes.mycawConcern1"
        outcome={clinicalAndExperienceOutcomes.mycawConcern1}
        concern={clinicalAndExperienceOutcomes.mycawConcern1.concern}
        disabled
      />
      <ClinicalExperienceAndOutcomesItem
        name="MYCaW CONCERN 2"
        formikId="clinicalAndExperienceOutcomes.mycawConcern2"
        outcome={clinicalAndExperienceOutcomes.mycawConcern2}
        concern={clinicalAndExperienceOutcomes.mycawConcern2.concern}
        disabled
      />

      <ClinicalExperienceAndOutcomesItem
        name="Overall wellbeing score"
        formikId="clinicalAndExperienceOutcomes.overallWellbeing"
        outcome={clinicalAndExperienceOutcomes.overallWellbeing}
        disabled
      />

      {clinicalAndExperienceOutcomes.otherFactors && (
        <ClinicalExperienceAndOutcomesItem
          name="Other factors affecting health"
          formikId="clinicalAndExperienceOutcomes.otherFactors"
          outcome={clinicalAndExperienceOutcomes.otherFactors}
          disabled
        />
      )}

      {clinicalAndExperienceOutcomes.mostImportantAspectOfSupport && (
        <ClinicalExperienceAndOutcomesItem
          name="Most important aspects of support from Perci Health"
          formikId="clinicalAndExperienceOutcomes.mostImportantAspectOfSupport"
          outcome={clinicalAndExperienceOutcomes.mostImportantAspectOfSupport}
          disabled
        />
      )}

      <ClinicalExperienceAndOutcomesItem
        name="Return to work self efficacy (RTW-SE)"
        formikId="clinicalAndExperienceOutcomes.returnToWorkSelfEfficacy"
        outcome={clinicalAndExperienceOutcomes.returnToWorkSelfEfficacy}
        disabled
      />

      <ClinicalExperienceAndOutcomesItem
        name="Fatigue"
        formikId="clinicalAndExperienceOutcomes.fatigue"
        outcome={clinicalAndExperienceOutcomes.fatigue}
        disabled
      />
    </>
  );
}
