import {
  CareDocumentOutcome,
  EbpCase,
  EbpTypeformType,
  MycawFollowUpForm,
  MycawFollowUpFormHelper,
  MycawInitialForm,
  MycawInitialFormHelper,
  WorkFocusForm,
  WorkFocusedPlanFormHelper,
} from '@packages/core-shared';
import { useLoadFirstForm } from '@packages/web-shared/api';
import { useGetFirestoreDocument } from '@packages/web-shared/hooks/useGetFirestoreDocument';
import { useEffect, useMemo, useState } from 'react';

type MycawOutcome = CareDocumentOutcome & { concern?: string };

export const useMycawLoading = ({ ebpCase }: { ebpCase: EbpCase | null }) => {
  const [mycawConcern1, setMycawConcern1] = useState<MycawOutcome>({});
  const [mycawConcern2, setMycawConcern2] = useState<MycawOutcome>({});
  const [overallWellbeing, setOverallWellbeing] = useState<MycawOutcome>({});
  const [mostImportantAspectOfSupport, setMostImportantAspectOfSupport] =
    useState<MycawOutcome | undefined>(undefined);
  const [otherFactors, setOtherFactors] = useState<MycawOutcome | undefined>(
    undefined,
  );
  const [fatigue, setFatigue] = useState<MycawOutcome>({});

  const [returnToWorkSelfEfficacy, setReturnToWorkSelfEfficacy] =
    useState<MycawOutcome>({});

  const [impacts, setImpacts] = useState<{
    psychological: string[];
    physical: string[];
    affectedRelationships: string[];
  }>({ psychological: [], physical: [], affectedRelationships: [] });

  const { record: mycawInitialForm } =
    useGetFirestoreDocument<MycawInitialForm>(
      'forms',
      ebpCase?.forms?.mycawInitialFormId,
    );

  const { record: mycawFollowUpForm } =
    useGetFirestoreDocument<MycawFollowUpForm>(
      'forms',
      ebpCase?.forms?.mycawFollowUpFormId,
    );

  // The same VRP PROMs form is used for both initial and follow-up scores
  // Initial - first form filled in
  // Follow-up - latest form filled in
  const { record: firstWorkFocusedForm } = useLoadFirstForm<WorkFocusForm>({
    patientEmail: ebpCase?.patient.email ?? null,
    formType: EbpTypeformType.WorkFocused,
  });
  const { record: latestWorkFocusedForm } =
    useGetFirestoreDocument<WorkFocusForm>(
      'forms',
      ebpCase?.forms?.workFocusedFormId,
    );

  useEffect(() => {
    const initialFormHelper = new MycawInitialFormHelper(mycawInitialForm);
    const followupFormHelper = new MycawFollowUpFormHelper(mycawFollowUpForm);
    const firstWorkFocusedFormHelper = new WorkFocusedPlanFormHelper(
      firstWorkFocusedForm,
    );
    const latestWorkFocusedFormHelper = new WorkFocusedPlanFormHelper(
      latestWorkFocusedForm,
    );

    const initialConcern1 = initialFormHelper.getInitialConcern1();
    const followUpConcern1Level = followupFormHelper.getConcern1Level();

    const concern1Change =
      initialConcern1?.level !== null && followUpConcern1Level !== null
        ? parseInt(followUpConcern1Level) - parseInt(initialConcern1?.level)
        : undefined;

    setMycawConcern1({
      concern: initialConcern1.concern ?? undefined,
      initial: initialConcern1?.level ?? undefined,
      followUp: followUpConcern1Level ?? undefined,
      change: changeToIntString(concern1Change),
    });

    const initialConcern2 = initialFormHelper.getInitialConcern2();
    const followUpConcern2Level = followupFormHelper.getConcern2Level();

    const concern2Change =
      initialConcern2?.level !== null && followUpConcern2Level !== null
        ? parseInt(followUpConcern2Level) - parseInt(initialConcern2?.level)
        : undefined;

    setMycawConcern2({
      concern: initialConcern2.concern ?? undefined,
      initial: initialConcern2?.level ?? undefined,
      followUp: followUpConcern2Level ?? undefined,
      change: changeToIntString(concern2Change),
    });

    const initialOverallWellbeingLevel =
      initialFormHelper.getFeelingLevel() ?? undefined;
    const followUpWellbeingLevel =
      followupFormHelper.getFeelingLevel() ?? undefined;

    const wellbeingChange =
      initialOverallWellbeingLevel && followUpWellbeingLevel
        ? parseInt(followUpWellbeingLevel) -
          parseInt(initialOverallWellbeingLevel)
        : undefined;
    setOverallWellbeing({
      initial: initialOverallWellbeingLevel,
      followUp: followUpWellbeingLevel,
      change: changeToIntString(wellbeingChange),
    });

    // The same VRP PROMs form is used for both initial and follow-up scores
    // Initial - first form filled in
    // Follow-up - latest form filled in
    const initialReturnToWorkSelfEfficacy =
      firstWorkFocusedFormHelper.getRtwSe();
    const followUpReturnToWorkSelfEfficacy =
      latestWorkFocusedForm &&
      firstWorkFocusedForm?.id !== latestWorkFocusedForm.id
        ? latestWorkFocusedFormHelper.getRtwSe()
        : undefined;
    const returnToWorkSelfEfficacyChange =
      initialReturnToWorkSelfEfficacy && followUpReturnToWorkSelfEfficacy
        ? parseFloat(followUpReturnToWorkSelfEfficacy) -
          parseFloat(initialReturnToWorkSelfEfficacy)
        : undefined;
    setReturnToWorkSelfEfficacy({
      initial: initialReturnToWorkSelfEfficacy,
      followUp: followUpReturnToWorkSelfEfficacy,
      change: changeToDecimalString(returnToWorkSelfEfficacyChange),
    });

    const initialFatigue = firstWorkFocusedFormHelper.getFatigue();
    const followUpFatigue =
      latestWorkFocusedForm &&
      firstWorkFocusedForm?.id !== latestWorkFocusedForm.id
        ? latestWorkFocusedFormHelper.getFatigue()
        : undefined;
    const fatigueChange =
      initialFatigue && followUpFatigue
        ? // 0 (no fatigue), 10 (worst fatigue you can imagine)
          // PDF output will use reverse coloring (-1 is better, +1 is worse)
          parseFloat(initialFatigue) - parseFloat(followUpFatigue)
        : undefined;
    setFatigue({
      initial: initialFatigue,
      followUp: followUpFatigue,
      change: changeToDecimalString(fatigueChange),
    });

    // Optional
    const mostImportantSupportAspectValue =
      followupFormHelper.getMostImportantSupportAspect();
    setMostImportantAspectOfSupport(
      mostImportantSupportAspectValue
        ? {
            initial: 'N/A baseline',
            followUp: mostImportantSupportAspectValue,
            change: 'N/a',
          }
        : undefined,
    );
    // Optional
    const otherFactorsValue = followupFormHelper.getOtherFactors();
    setOtherFactors(
      otherFactorsValue
        ? {
            initial: 'N/A baseline',
            followUp: otherFactorsValue,
            change: 'N/a',
          }
        : undefined,
    );

    setOtherFactors({
      followUp: followupFormHelper.getOtherFactors() ?? undefined,
    });

    setImpacts({
      physical: initialFormHelper.getPhysicalImpacts(),
      psychological: initialFormHelper.getPsychologicalImpacts(),
      affectedRelationships: initialFormHelper.getAffectedRelationships(),
    });
  }, [
    mycawInitialForm,
    mycawFollowUpForm,
    firstWorkFocusedForm,
    latestWorkFocusedForm,
  ]);

  const result = useMemo(
    () => ({
      mycawConcern1,
      mycawConcern2,
      overallWellbeing,
      returnToWorkSelfEfficacy,
      impacts,
      otherFactors,
      mostImportantAspectOfSupport,
      fatigue,
    }),
    [
      mycawConcern1,
      mycawConcern2,
      overallWellbeing,
      returnToWorkSelfEfficacy,
      impacts,
      otherFactors,
      mostImportantAspectOfSupport,
      fatigue,
    ],
  );

  return result;
};

const changeToIntString = (change: number | undefined) =>
  change ? `${change > 0 ? '+' : '-'}${Math.abs(change)}` : undefined;
const changeToDecimalString = (change: number | undefined) =>
  change
    ? `${change > 0 ? '+' : '-'}${Math.abs(change).toFixed(2)}`
    : undefined;
