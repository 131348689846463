import { useLoadSafeGuardableForms } from '../../../../../../../api';
import { AlertError, Spinner } from '@percihealth/react';
import CollapsibleContainer from '../../CollapsibleContainer';
import SafeGuardableFormComponent from '../SafeGuardableFormComponent';
import {
  EbpTypeformType,
  MycawInitialForm,
  dateTimeToLocalDateTimeString,
} from '@packages/core-shared';
import { default as CheckmarkSvg } from './checkmark.svg';
import { default as WarningSvg } from './warning.svg';
import styles from './SafeGuardableFormsList.module.scss';

interface Props {
  patientEmail?: string;
  allowEditSafeguardCheck: boolean;
  formType: EbpTypeformType;
}

export default function SafeGuardableFormsList({
  patientEmail,
  formType,
  allowEditSafeguardCheck,
}: Props) {
  const formsLoader = useLoadSafeGuardableForms({
    patientEmail,
    formType,
  });

  if (formsLoader.loading) {
    return <Spinner />;
  }

  if (formsLoader.errorMessage) {
    return <AlertError>{formsLoader.errorMessage}</AlertError>;
  }

  const getFormName = (form: MycawInitialForm) => (
    <>
      {`${dateTimeToLocalDateTimeString(form.createdAt)}`}
      <img
        className={styles['status-icon']}
        src={form.safeguardCheck ? CheckmarkSvg : WarningSvg}
      />
    </>
  );

  return (
    <div className={styles.container}>
      {formsLoader.records.map((form) => (
        <CollapsibleContainer key={form.id} name={getFormName(form)}>
          <SafeGuardableFormComponent
            form={form}
            allowEditSafeguardCheck={allowEditSafeguardCheck}
          />
        </CollapsibleContainer>
      ))}
    </div>
  );
}
