import { AlertError } from '@percihealth/react';
import { useMemo, useState } from 'react';
import { ReferralsApiRepository } from '../../../../../api';
import { useFirebase } from '../../../../../context';

export const ReferralDownloadFileLink = ({
  referralId,
  fileName,
  filePath,
}: {
  referralId: string;
  fileName?: string;
  filePath?: string;
}) => {
  const { auth } = useFirebase();

  const referralsApiRepository = useMemo(
    () => new ReferralsApiRepository(auth),
    [auth],
  );

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleDownloadClick = async (e: any) => {
    e.preventDefault();

    if (!filePath || !fileName) {
      return;
    }
    try {
      setErrorMessage(null);

      const previewUrl = await referralsApiRepository.generateDownloadUrl({
        referralId,
        filePath,
      });

      if (!previewUrl) {
        setErrorMessage('Unable to generate download URL');
        return;
      }

      // Open the file in a new tab, so it is not automatically downloaded to the local storage
      window.open(previewUrl, '_blank');
    } catch (err: any) {
      setErrorMessage(err.message);
    }
  };

  return (
    <div>
      {fileName && filePath ? (
        <a onClick={handleDownloadClick}>{fileName}</a>
      ) : (
        <span style={{ wordBreak: 'break-word' }}>{fileName}</span>
      )}
      {errorMessage && <AlertError>{errorMessage}</AlertError>}
    </div>
  );
};
