import { Button, InputText } from '@percihealth/react';
import { useState } from 'react';
import styles from './PcpConcernAdd.module.css';

interface Props {
  onAdd: (concern: string) => void;
}

export default function PcpConcernAdd({ onAdd }: Props) {
  const [concern, setConcern] = useState('');

  return (
    <div className={styles['add-container']}>
      <InputText
        value={concern}
        onChange={(e) => setConcern(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            onAdd(concern);
            setConcern('');
          }
        }}
      />
      <Button
        type="button"
        className={styles.add}
        onClick={(e) => {
          e.preventDefault();
          onAdd(concern);
          setConcern('');
        }}
      >
        Add
      </Button>
    </div>
  );
}
