import { getApp } from 'firebase/app';
import { Auth, getAuth } from 'firebase/auth';

export class ApiRepository {
  constructor(protected readonly firebaseAuth: Auth = getAuth(getApp())) {}

  protected async getToken() {
    return (await this.firebaseAuth.currentUser?.getIdTokenResult(true))?.token;
  }
}
