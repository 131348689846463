import { useMemo } from 'react';
import { ReferralsApiRepository } from '../../../../../../api';
import ReferralDocumentsRow from './ReferralDocumentsRow';
import styles from './ReferralDocumentsTable.module.css';
import { useFirebase } from '../../../../../../context';

export default function ReferralDocumentsTable({
  referralId,
  forms,
  readonly,
}: {
  referralId: string;
  forms: {
    path?: string;
    name: string;
    uploadedToHealee?: { at: Date; by?: string | null };
  }[];
  readonly: boolean;
}) {
  const { auth } = useFirebase();

  const referralsApiRepository = useMemo(
    () => new ReferralsApiRepository(auth),
    [auth],
  );

  return (
    <table className={styles.table}>
      <thead>
        <tr style={{ textAlign: 'left' }}>
          <th>#</th>
          <th>File</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {forms.map((referralDoc, idx) => (
          <ReferralDocumentsRow
            rowNumber={idx + 1}
            referralId={referralId}
            referralsApiRepository={referralsApiRepository}
            key={referralDoc.path ?? referralDoc.name}
            referralDoc={referralDoc}
            readonly={readonly}
          />
        ))}
      </tbody>
    </table>
  );
}
