import { useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTable } from 'react-table';
import styles from './CaseListPage.module.scss';
import {
  AlertError,
  Button,
  Checkbox,
  Datepicker,
  DropdownSearch,
  // DropdownSearch,
  Spinner,
  Tooltip,
} from '@percihealth/react';
import {
  useLoadCases,
  useLoadOrganizations,
  useLoadUsers,
} from '../../../../api';
import { useAuth } from '@percihealth/react-auth';
import {
  dateTimeToLocalDateTimeString,
  earliestDayTime,
  latestDayTime,
  localTimeZone,
} from '@packages/core-shared';
import QuestionIcon from '../../../icons/QuestionIcon';

type UIEbpCase = {
  id: string;
  patientFullname: string;
  expertId?: string;
  createdAt?: string;
  updatedAt?: string;
};

export default function CaseListPage() {
  const { user } = useAuth();

  const expertsLoader = useLoadUsers({ expert: true });
  useEffect(() => {
    expertsLoader.load();
  }, [user]);
  const [expertId, setExpertId] = useState<string | null>(
    localStorage.getItem('filter_expert_id') ?? user?.email ?? null,
  );
  const [expertIds, setExpertIds] = useState<string[]>([]);
  useEffect(() => {
    if (expertsLoader.loading || expertsLoader.records.length === 0) {
      return;
    }

    const emails = expertsLoader.records.map((user) => user.email);

    emails.sort();
    setExpertIds(emails);

    const expertEmail = localStorage.getItem('filter_expert_id') ?? user?.email;
    const newExpertId =
      expertEmail && emails.includes(expertEmail) ? expertEmail : null;

    setExpertId(newExpertId);
  }, [expertsLoader.loading, expertsLoader.records]);

  const organizationsLoader = useLoadOrganizations();
  useEffect(() => {
    organizationsLoader.load();
  }, [user]);
  const [organizationId, setOrganizationId] = useState<string | null>(
    localStorage.getItem('filter_organization_id') ?? null,
  );

  useEffect(() => {
    if (organizationsLoader.records.length === 0) {
      return;
    }

    const orgIds = organizationsLoader.records.map((org) => org.id);

    const orgId = localStorage.getItem('filter_organization_id');
    const newOrgId = orgId && orgIds.includes(orgId) ? orgId : null;

    setOrganizationId(newOrgId);
  }, [organizationsLoader.records]);

  const organizationOptions = useMemo(() => {
    const orgs = [...organizationsLoader.records];
    orgs.sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''));
    const options = [
      { value: undefined, name: 'All' },
      ...orgs.map((org) => ({ value: org.id, name: org.name })),
    ];

    return options;
  }, [organizationsLoader.records]);

  const [fromDate, setFromDate] = useState<Date | null>(
    localStorage.getItem('filter_from')
      ? new Date(localStorage.getItem('filter_from')!)
      : earliestDayTime(
          new Date(new Date().setMonth(new Date().getMonth() - 6)),
        ),
  );
  const [toDate, setToDate] = useState<Date | null>(
    localStorage.getItem('filter_to')
      ? new Date(localStorage.getItem('filter_to')!)
      : null,
  );

  const [showOnlyActiveCases, setShowOnlyActiveCases] = useState(
    localStorage.getItem('filter_show_only_active_cases')
      ? Boolean(localStorage.getItem('filter_show_only_active_cases'))
      : false,
  );

  // const allFollowedUpFilterOptions = [
  //   { value: 'true', name: 'Yes' },
  //   { value: 'false', name: 'No' },
  // ];
  // const [followedUpFilter /*, setFollowedUpFilter*/] = useState(
  //   allFollowedUpFilterOptions.map((f) => f.value),
  // );
  const casesLoader = useLoadCases(
    fromDate,
    toDate,
    expertId,
    organizationId,
    showOnlyActiveCases,
    // followedUpFilter.map((f) => f === 'true'),
  );

  useEffect(() => {
    casesLoader.load();
  }, [
    user,
    fromDate,
    toDate,
    expertId,
    organizationId,
    showOnlyActiveCases,
    //  followedUpFilter
  ]);

  const detailsCellView = ({ row }: { row: any }) => {
    return row.values.id ? (
      <Link to={`/cases/${row.values.id}`}>View</Link>
    ) : (
      <span>-</span>
    );
  };

  // const followedCellView = ({ row }) =>
  //   row.values.followedUp ? (
  //     <img className={styles.followicon} src="/img/checkmark.svg" />
  //   ) : (
  //     <img className={styles.followicon} src="/img/warning.svg" />
  //   );

  const columns: any = useMemo(
    () => [
      {
        Header: 'Expert ID',
        accessor: 'expertId',
      },
      {
        Header: 'Patient name',
        accessor: 'patientFullname',
      },
      {
        Header: 'Patient email',
        accessor: 'patient.email',
      },
      {
        Header: 'Organization',
        accessor: 'organization.name',
      },
      {
        Header: 'Has apt.',
        accessor: 'hasAppointment',
      },
      {
        Header: 'Last activity',
        accessor: 'updatedAt',
      },
      {
        Header: 'Created',
        accessor: 'createdAt',
      },
      {
        Header: 'Details',
        accessor: 'id',
        Cell: detailsCellView,
      },
    ],
    [],
  );
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data: casesLoader.records.map((data) => {
        return {
          ...data,
          id: data.patient?.email ?? '',
          expertId: data.expertId,
          patientFullname: `${data.patient?.firstName ?? ''} ${
            data.patient?.lastName ?? ''
          }`,
          hasAppointment: data.hasAppointment ? 'Yes' : 'No',
          createdAt: dateTimeToLocalDateTimeString(data.createdAt),
          updatedAt: dateTimeToLocalDateTimeString(data.updatedAt),
        } as UIEbpCase;
      }),
    });

  return (
    <>
      <div>
        <div className={styles['filter-container']}>
          <Datepicker
            label="From:"
            value={fromDate}
            onChange={(date: Date | null) => {
              localStorage.setItem(
                'filter_from',
                date ? date.toISOString() : '',
              );
              setFromDate(date);
            }}
          />
          <Datepicker
            label="To:"
            value={toDate}
            onChange={(date: Date | null) => {
              localStorage.setItem('filter_to', date ? date.toISOString() : '');
              setToDate(date ? latestDayTime(date) : null);
            }}
          />
          <DropdownSearch
            label="Organisation:"
            width="auto"
            closeOnSelect
            value={organizationId ?? undefined}
            onChange={(values) => {
              const val = values ? (values as string) : null;
              localStorage.setItem('filter_organization_id', val ?? '');
              setOrganizationId(val);
            }}
            options={organizationOptions}
          />

          <DropdownSearch
            label="Expert:"
            closeOnSelect
            value={expertId ?? undefined}
            onChange={(values) => {
              const val = values ? (values as string) : null;
              localStorage.setItem('filter_expert_id', val ?? '');
              setExpertId(val);
            }}
            options={[undefined, ...expertIds].map((id) => ({
              name: id ?? 'All',
              value: id,
            }))}
          />
          {/* Hid follow up as it is disable (always true now) */}
          {/* Followed up:
          <DropdownSearch
            closeOnSelect
            multiple
            value={followedUpFilter}
            onChange={(values) => setFollowedUpFilter(values as string[])}
            options={allFollowedUpFilterOptions}
          /> */}
        </div>
        <div className={styles['filter-active-container']}>
          <Checkbox
            checked={showOnlyActiveCases}
            value={'setShowOnlyActiveCases'}
            text={'Show active cases only'}
            onChange={(e) => {
              localStorage.setItem(
                'filter_show_only_active_cases',
                e.target.checked.toString(),
              );
              setShowOnlyActiveCases(e.target.checked);
            }}
          />
          <Tooltip
            trigger={
              <div>
                <QuestionIcon className={styles['filter-active__hint']} />
              </div>
            }
          >
            <div>
              An active case is not discharged and has at least one appointment
              in any status.
            </div>
          </Tooltip>
        </div>
      </div>
      <div className={styles['content-container']}>
        {casesLoader.errorMessage && (
          <AlertError>{casesLoader.errorMessage}</AlertError>
        )}
        <table {...getTableProps()} className={styles.table}>
          <thead>
            {headerGroups.map((headerGroup: any) => {
              const { key: headerKey, ...headerProps } =
                headerGroup.getHeaderGroupProps();
              return (
                <tr key={headerKey} {...headerProps}>
                  {headerGroup.headers.map((column: any) => {
                    const { key: columnKey, ...columnProps } =
                      column.getHeaderProps();
                    return (
                      <th key={columnKey} {...columnProps}>
                        {column.render('Header')}
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row: any) => {
              prepareRow(row);
              const { key: rowKey, ...rowProps } = row.getRowProps();
              return (
                <tr key={rowKey} {...rowProps}>
                  {row.cells.map((cell: any) => {
                    const { key: cellKey, ...cellProps } = cell.getCellProps();
                    return (
                      <td key={cellKey} {...cellProps}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        {casesLoader.loading && <Spinner className={styles.spinner} />}
        {!casesLoader.loading && casesLoader.canLoadNextPage && (
          <Button onClick={casesLoader.loadNextPage}>Load more</Button>
        )}

        <p className={styles.note}>Time is in the {localTimeZone()} zone.</p>
      </div>
    </>
  );
}
