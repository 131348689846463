export enum EbpMedicalHistoryField {
  MemberDob = 'MemberDob',
  MemberAddress = 'MemberAddress',
  CancerType = 'CancerType',
  GeneralPractice = 'GeneralPractice',
}

export const getColorForField = (field: EbpMedicalHistoryField) => {
  switch (field) {
    // Use hard-coded values so it is working in context 2d draw
    case EbpMedicalHistoryField.MemberAddress:
      return '#5f908f'; //'var(--global-color-primary-steel-teal)';
    case EbpMedicalHistoryField.MemberDob:
      return '#4EC95D';
    case EbpMedicalHistoryField.CancerType:
      return '#134855'; //'var(--global-color-secondary-calm)';
    case EbpMedicalHistoryField.GeneralPractice:
      return '#58A4B0';
    default:
      return 'black';
  }
};
