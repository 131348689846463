import styles from './SignedInHeaderSection.module.css';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@percihealth/react-auth';
import { useFirebase } from '../../../../context';

export default function SignedInHeaderSection() {
  const { user } = useAuth();
  const { auth } = useFirebase();
  const navigate = useNavigate();

  if (!user) return <></>;

  return (
    <>
      <span className={styles['display-name']}>{user.displayName}</span>
      <a
        onClick={() => {
          auth.signOut();
          navigate('/login');
        }}
      >
        Sign-out
      </a>
    </>
  );
}
