import { Link } from 'react-router-dom';
import { useGetFirestoreDocument } from '../../../../../../../hooks/useGetFirestoreDocument';
import {
  MycawInitialForm,
  MycawInitialFormHelper,
} from '@packages/core-shared';
import { AlertError, Spinner } from '@percihealth/react';
import styles from './MycawFollowUpFormLink.module.scss';

interface Props {
  email?: string;
  patientFullName?: string;
  mycawInitialFormId?: string;
}

export const MycawFollowUpFormLink = ({
  email,
  patientFullName,
  mycawInitialFormId,
}: Props) => {
  const mycawInitialFormLoader = useGetFirestoreDocument<MycawInitialForm>(
    'forms',
    mycawInitialFormId ?? null,
  );

  if (mycawInitialFormLoader.loading) {
    return <Spinner />;
  }
  if (mycawInitialFormLoader.errorMessage) {
    return <AlertError>{mycawInitialFormLoader.errorMessage} </AlertError>;
  }

  const formHelper = new MycawInitialFormHelper(mycawInitialFormLoader.record);
  const link = formHelper.buildFollowUpFormLink({
    followUpFormId: process.env.REACT_APP_MYCAW_FOLLOW_UP_FORM_ID!,
    email,
    patientFullName,
  });

  if (!link) {
    return <></>;
  }

  return (
    <div className={styles.container}>
      <Link to={link} target="_blank">
        Add follow up form
      </Link>
    </div>
  );
};
