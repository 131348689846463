import { useEffect, useMemo, useState } from 'react';
import styles from './CaseDetailsPage.module.scss';
// import CaseFollowUps from './CaseFollowUps';
import {
  cancerStatusToString,
  dateToLondonLongDateString,
  EbpCase,
  ebpCaseFinalStatuses,
  EbpCaseStatus,
  CareDocumentType,
  EbpLandgCase,
  LandgCareDocument,
  EbpTypeformType,
  OrganizationId,
  ReferralLandg,
  dateTimeToLocalDateTimeString,
} from '@packages/core-shared';
import { useLocation, useParams } from 'react-router-dom';
import {
  AlertError,
  Button,
  Checkbox,
  FormFieldset,
  FormLabel,
  InputText,
  Spinner,
  Tooltip,
} from '@percihealth/react';
import AppointmentsHistory from '../../AppointmentsHistory';
import ExpertChangeHistory from '../../ExpertChangeHistory';
import { CasesApiRepository, useLoadServices } from '../../../../api';
import { EbpDocumentsTable } from './EbpDocumentsTable';
import MycawFollowUpFormsList from './Forms/MycawFollowUpForms/MycawFollowUpFormsList';
import { MycawFollowUpFormLink } from './Forms/MycawFollowUpForms/MycawFollowUpFormLink';
import { MycawInitialFormLink } from './Forms/MycawInitialForms/MycawInitialFormLink';
import ConsentShareDataToInsurerHistory from '../../ConsentShareDataToInsurerHistory';
import { useSubscribeFirestoreDocument } from '../../../../hooks/useSubscribeFirestoreDocument';
import EmailShareActivity from '../../EmailShareActivity';
import { WorkFocusedFormLink } from './Forms/WorkFocusedForms/WorkFocusedFormLink';
import SafeGuardableFormsList from './Forms/SafeGuardableForms/SafeGuardableFormsList';
import LevelDropdown from '../../LevelDropdown';
import ExpertInternalNotesTable from './ExpertInternalNotesTable';
import { useSubscribeCareDocumentsByCaseId } from '../../../../api/care-documents/useSubscribeCareDocumentsByCaseId';
import { DischargeConfirmationModal } from './Discharge/DischargeConfirmationModal';
import CaseStatusBar from './CaseStatusBar';
import { LandgReferralDetails } from './Referrals/LandgReferralDetails';
import LandgRequestPreauthorizationFormLink from './LandgRequestPreauthorizationFormLink';
import { useGetFirestoreDocument } from '../../../../hooks/useGetFirestoreDocument';
import { useFirebase } from '../../../../context';

interface Props {
  allowEditExpert: boolean;
  allowEditPdfs: boolean;
  allowEditTypeforms: boolean;
  allowEditConsentShareDataToInsurer: boolean;
  allowEditPatientLevel: boolean;
}

export default function CaseDetailsPage({
  allowEditExpert,
  allowEditPdfs,
  allowEditTypeforms,
  allowEditConsentShareDataToInsurer,
  allowEditPatientLevel,
}: Props) {
  const params = useParams();
  const { hash } = useLocation();
  const id = params?.id ?? null;

  if (!id) {
    return <div>Case ID (email) should be specified</div>;
  }

  const servicesLoader = useLoadServices();

  const { auth } = useFirebase();
  const casesApiRepository = useMemo(
    () => new CasesApiRepository(auth),
    [auth],
  );
  const [expertId, setExpertId] = useState('');
  const [updatingExpertId, setUpdatingExpertId] = useState(false);
  const [updateExpertIdErrorMessage, setUpdateExpertIdErrorMessage] = useState<
    string | null
  >(null);
  const [
    updatingConsentShareDataToInsurer,
    setUpdatingConsentShareDataToInsurer,
  ] = useState(false);
  const [
    updateConsentShareDataToInsurerErrorMessage,
    setUpdateConsentShareDataToInsurerErrorMessage,
  ] = useState<string | null>(null);

  const {
    record: ebpCase,
    loading: caseLoading,
    errorMessage: caseLoadingErrorMessage,
  } = useSubscribeFirestoreDocument<EbpCase>('cases', id);

  const readonly = useMemo(
    () => !!ebpCase?.status && ebpCaseFinalStatuses.includes(ebpCase?.status),
    [ebpCase?.status, ebpCaseFinalStatuses],
  );

  useEffect(() => {
    if (!caseLoading && hash) {
      const element = document.getElementById(hash.slice(1));
      if (element) {
        element.scrollIntoView();
      }
    }
  }, [hash, caseLoading]);

  useEffect(() => {
    setExpertId(ebpCase?.expertId ?? '');
  }, [ebpCase]);

  const { record: landgReferral } = useGetFirestoreDocument<ReferralLandg>(
    'referrals',
    ebpCase?.organization.id === OrganizationId.landg
      ? ebpCase?.referralId
      : undefined,
  );

  const documentsLoader = useSubscribeCareDocumentsByCaseId({
    caseId: ebpCase?.id ?? '-1',
  });

  const canDischarge = useMemo(() => {
    return (
      ebpCase?.organization.id === OrganizationId.landg &&
      documentsLoader.records.some(
        (doc) =>
          doc.type === CareDocumentType.EndOfCareSummary &&
          (doc as LandgCareDocument).sentToCaseManager,
      )
    );
  }, [ebpCase?.organization.id, documentsLoader.records]);

  const [showDischargeModal, setShowDischargeModal] = useState(false);

  useEffect(() => {
    setShowDischargeModal(false);
  }, [ebpCase?.id]);

  const updateExpertId = async (caseId: string, expertId: string) => {
    try {
      setUpdatingExpertId(true);
      setUpdateExpertIdErrorMessage(null);
      await casesApiRepository.updateExpertId(caseId, expertId);
    } catch (err) {
      console.error(err);
      setUpdateExpertIdErrorMessage(
        err instanceof Error ? err.message : JSON.stringify(err),
      );
    } finally {
      setUpdatingExpertId(false);
    }
  };

  const updateConsentShareDataToInsurer = async (
    caseId: string,
    consentShareDataToInsurer: boolean,
  ) => {
    try {
      setUpdatingConsentShareDataToInsurer(true);
      setUpdateConsentShareDataToInsurerErrorMessage(null);
      await casesApiRepository.updateConsentShareDataToInsurer(
        caseId,
        consentShareDataToInsurer,
      );
    } catch (err) {
      console.error(err);
      setUpdateConsentShareDataToInsurerErrorMessage(
        err instanceof Error ? err.message : JSON.stringify(err),
      );
    } finally {
      setUpdatingConsentShareDataToInsurer(false);
    }
  };

  if (caseLoading) {
    return <Spinner />;
  }
  if (caseLoadingErrorMessage) {
    return <AlertError>{caseLoadingErrorMessage}</AlertError>;
  }
  if (!ebpCase) {
    return <>Not found</>;
  }

  return (
    <>
      <div className={styles.container}>
        {ebpCase?.status && (
          <CaseStatusBar
            status={ebpCase.status}
            createdAt={ebpCase.createdAt}
            dischargeAt={ebpCase.dischargedAt}
          />
        )}
        {/* Discharge */}
        {ebpCase?.status !== EbpCaseStatus.discharged &&
          ebpCase.organization.id === OrganizationId.landg && (
            <div style={{ marginBottom: '-24px' }}>
              <div style={{ display: 'flex', justifyContent: 'end' }}>
                <Tooltip
                  disabled={canDischarge}
                  position="left center"
                  trigger={
                    <div>
                      <Button
                        className="discharge-btn"
                        disabled={readonly || !canDischarge}
                        onClick={() => {
                          setShowDischargeModal(true);
                        }}
                      >
                        Discharge
                      </Button>
                    </div>
                  }
                >
                  <div>
                    available when "End of Care summary" is shared with the L&G
                    case manager
                  </div>
                </Tooltip>
              </div>
              <DischargeConfirmationModal
                caseId={ebpCase.id}
                patientFullname={`${ebpCase.patient.firstName} ${ebpCase.patient.lastName}`}
                open={showDischargeModal}
                onClose={() => setShowDischargeModal(false)}
              />
            </div>
          )}
        <FormFieldset title="General">
          <div>
            <FormLabel bold>Email</FormLabel>
            <span>{ebpCase.id}</span>
          </div>
          <div>
            <FormLabel bold>Organization</FormLabel>
            <span>{ebpCase.organization?.name}</span>
          </div>
          <div>
            <FormLabel bold>Patient name</FormLabel>
            <span>{`${ebpCase.patient?.firstName} ${ebpCase.patient?.lastName}`}</span>
          </div>
          <div>
            <FormLabel bold>Patient DOB</FormLabel>
            <span>
              {ebpCase.patient?.dateOfBirth
                ? dateToLondonLongDateString(ebpCase.patient?.dateOfBirth)
                : '-'}
            </span>
          </div>
          <div>
            <FormLabel bold>Cancer status</FormLabel>
            <span>
              {ebpCase.patient?.cancerStatus
                ? cancerStatusToString(ebpCase.patient?.cancerStatus)
                : '-'}
            </span>
          </div>
          {(readonly || !allowEditExpert) && (
            <div>
              <FormLabel bold>Expert ID</FormLabel>
              <span>{ebpCase.expertId}</span>
            </div>
          )}
          {!readonly && allowEditExpert && (
            <div>
              <FormLabel bold>Expert ID</FormLabel>
              {updateExpertIdErrorMessage && (
                <AlertError>{updateExpertIdErrorMessage}</AlertError>
              )}
              <div className={styles.expertId}>
                <InputText
                  disabled={readonly || !allowEditExpert}
                  className={styles.expertIdInput}
                  value={expertId}
                  onChange={(e) => setExpertId(e.currentTarget.value)}
                />
                <Button
                  className={styles.saveExpertIdButton}
                  onClick={() => updateExpertId(ebpCase.id, expertId)}
                  submitting={updatingExpertId}
                >
                  Save
                </Button>
              </div>
              <ExpertChangeHistory caseId={ebpCase.id} />
            </div>
          )}
          <div>
            <FormLabel bold>PCP created</FormLabel>
            <span>{ebpCase.documents?.pcp ? 'Yes' : 'No'}</span>
          </div>
          {OrganizationId.landg === ebpCase.organization?.id && (
            <div>
              <FormLabel bold>Level</FormLabel>
              <LevelDropdown
                caseId={ebpCase.id}
                disabled={readonly || !allowEditPatientLevel}
                initialValue={ebpCase.patient.level}
              />
            </div>
          )}
          <div>
            <FormLabel bold>Last activity</FormLabel>
            <span>{dateTimeToLocalDateTimeString(ebpCase.updatedAt)}</span>
          </div>
          <div>
            <FormLabel bold>Created</FormLabel>
            <span>{dateTimeToLocalDateTimeString(ebpCase.createdAt)}</span>
          </div>
          {!readonly && ebpCase.organization.id === OrganizationId.landg && (
            <div>
              <FormLabel bold>Pre-authorisation</FormLabel>
              <LandgRequestPreauthorizationFormLink
                patientFullname={`${ebpCase.patient?.firstName} ${ebpCase.patient?.lastName}`}
                careLevel={ebpCase.patient.level}
                gipCode={landgReferral?.gip?.code}
                // L&G does not have a referrer, using case manager
                // https://perci-health.monday.com/boards/6276536070/pulses/7095615024
                referrerEmail={landgReferral?.caseManager?.email}
              />
            </div>
          )}
        </FormFieldset>
        {ebpCase.organization?.id === OrganizationId.landg && (
          <FormFieldset title="Referral details">
            <LandgReferralDetails
              ebpCase={ebpCase as EbpLandgCase}
              readonly={readonly}
            />
          </FormFieldset>
        )}

        <FormFieldset title="Consent">
          <div>
            {updateConsentShareDataToInsurerErrorMessage && (
              <AlertError>
                {updateConsentShareDataToInsurerErrorMessage}
              </AlertError>
            )}
            <Checkbox
              text="Consent gained to share medical data back to insurer?"
              disabled={
                readonly ||
                !allowEditConsentShareDataToInsurer ||
                updatingConsentShareDataToInsurer
              }
              value=""
              checked={!!ebpCase.consentShareDataToInsurer}
              onChange={(e) => {
                updateConsentShareDataToInsurer(ebpCase.id, e.target.checked);
              }}
            />
            <div>{updatingConsentShareDataToInsurer && <Spinner />}</div>
          </div>
          <ConsentShareDataToInsurerHistory caseId={ebpCase.id} />
        </FormFieldset>

        <FormFieldset title="Internal notes">
          <ExpertInternalNotesTable caseId={ebpCase.id} readonly={readonly} />
        </FormFieldset>

        <FormFieldset title="Platform activity">
          {servicesLoader.servicesLoading && <Spinner />}
          {servicesLoader.servicesError && (
            <AlertError>{servicesLoader.servicesError}</AlertError>
          )}
          <div id="activity" className="full-width">
            {!servicesLoader.servicesLoading && (
              <AppointmentsHistory
                organizationId={ebpCase?.organization.id}
                services={servicesLoader.services}
                patientEmail={ebpCase.patient?.email}
                disabled={readonly}
              />
            )}
          </div>
          {/* <div className="full-width">
              {servicesLoader.servicesLoading && <Spinner />}
              {servicesLoader.servicesError && (
                <AlertError>{servicesLoader.servicesError}</AlertError>
              )}
              {!servicesLoader.servicesLoading && (
                <CaseFollowUps
                  patientEmail={ebpCase.patient?.email}
                  followedUp={ebpCase.followedUp}
                  services={servicesLoader.services}
                  readOnly={false}
                />
              )}
            </div> */}
        </FormFieldset>
        <FormFieldset title="Forms">
          <div>
            <p className={styles['form-header']}>Initial MYCAW</p>
            {!readonly && allowEditTypeforms && (
              <MycawInitialFormLink
                cancerStatus={ebpCase?.patient?.cancerStatus}
                email={ebpCase?.patient?.email}
                fullName={
                  ebpCase?.patient
                    ? `${ebpCase.patient.firstName} ${ebpCase.patient.lastName}`
                    : undefined
                }
              />
            )}
            <SafeGuardableFormsList
              allowEditSafeguardCheck={!readonly && allowEditTypeforms}
              formType={EbpTypeformType.MycawInitial}
              patientEmail={ebpCase?.patient?.email}
            />
          </div>
          {ebpCase.organization.id === OrganizationId.landg && (
            <div>
              <p className={styles['form-header']}>VRP PROMs</p>
              {!readonly && allowEditTypeforms && (
                <WorkFocusedFormLink
                  email={ebpCase?.patient?.email}
                  fullName={
                    ebpCase?.patient
                      ? `${ebpCase.patient.firstName} ${ebpCase.patient.lastName}`
                      : undefined
                  }
                />
              )}
              <SafeGuardableFormsList
                allowEditSafeguardCheck={!readonly && allowEditTypeforms}
                formType={EbpTypeformType.WorkFocused}
                patientEmail={ebpCase?.patient?.email}
              />
            </div>
          )}
          <div>
            <p className={styles['form-header']}>Follow up MYCAW</p>
            {!readonly && allowEditTypeforms && (
              <MycawFollowUpFormLink
                email={ebpCase?.patient?.email}
                patientFullName={
                  ebpCase?.patient
                    ? `${ebpCase.patient.firstName} ${ebpCase.patient.lastName}`
                    : undefined
                }
                mycawInitialFormId={ebpCase?.forms?.mycawInitialFormId}
              />
            )}
            <MycawFollowUpFormsList
              mycawInitialFormId={ebpCase?.forms?.mycawInitialFormId}
              patientEmail={ebpCase?.patient?.email}
            />
          </div>
        </FormFieldset>
        <FormFieldset title="Documents">
          <EbpDocumentsTable
            ebpCase={ebpCase}
            caseId={id}
            organizationId={ebpCase.organization.id}
            allowEditPdfs={!readonly && allowEditPdfs}
            allowUploadPdfs={!readonly}
            documentsLoader={documentsLoader}
          />
        </FormFieldset>
        {(ebpCase.organization.id === OrganizationId.healix ||
          ebpCase.organization.id === OrganizationId.landg) && (
          <FormFieldset title="Sharing activity">
            <EmailShareActivity
              caseId={ebpCase.patient?.email}
              documentsLoader={documentsLoader}
              readonly={readonly}
            />
          </FormFieldset>
        )}
      </div>
    </>
  );
}
