import { CareDocumentResource } from '@packages/core-shared';
import {
  AlertError,
  Button,
  DropdownSearch,
  InputText,
} from '@percihealth/react';
import { useState } from 'react';
import styles from './DocumentResourceList.module.css';

const TypeOptions = ['Article', 'Video', 'Podcast', 'Other'];

interface Props {
  resources: CareDocumentResource[];
  onChange: (arr: CareDocumentResource[]) => void;
}

export default function DocumentResourceList({ resources, onChange }: Props) {
  const [type, setType] = useState(TypeOptions[0]);
  const [name, setName] = useState('');
  const [link, setLink] = useState('');

  const [error, setError] = useState<string | null>(null);

  const add = () => {
    if (!type || !name || !link) {
      setError('Please fill in all fields');
      return;
    }

    if (resources.some((r) => r.name === name && r.type === type)) {
      setError('This resource already exists');
      return;
    }

    setError(null);

    const arr = [...resources];
    arr.push({ type, name, link });
    onChange(arr.map((res, index) => ({ ...res, id: index + 1 })));
  };

  const remove = (index: number) => {
    onChange(resources.filter((_, idx) => idx !== index));
  };

  return (
    <div className="full-width">
      <p className="description">
        We want you to feel supported. This list consists of article links,
        videos, podcasts and other resources which you can refer to at any time
        you need.
      </p>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Type</th>
            <th>Name</th>
            <th>Link</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {resources.map((row, index) => (
            <tr key={`${row.type}_${row.name ?? ''}`}>
              <td>{row.type}</td>
              <td>{row.name}</td>
              <td>{row.link}</td>
              <td>
                <a onClick={() => remove(index)}>Delete</a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div
        className={styles['add-container']}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.stopPropagation();
            e.preventDefault();
            add();
          }
        }}
      >
        <DropdownSearch
          label="Type"
          required
          multiple={false}
          value={type}
          options={TypeOptions.map((o) => ({
            value: o,
            name: o,
          }))}
          onChange={(e) => setType(e as any)}
        />
        <InputText
          label="Name"
          required
          onChange={(e) => setName(e.target.value)}
        />
        <InputText
          label="Link"
          required
          onChange={(e) => setLink(e.target.value)}
        />
        <Button type="button" className={styles.add} onClick={add}>
          Add
        </Button>
      </div>
      {error && <AlertError>{error}</AlertError>}
    </div>
  );
}
