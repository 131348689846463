import { AlertError, Button, Checkbox, Modal } from '@percihealth/react';
import { useEffect, useState } from 'react';
import styles from './DischargeConfirmationModal.module.css';
import { EbpCase, ebpCaseEmails } from '@packages/core-shared';
import { getFetchResponseErrorMessage } from '../../../../../helpers/getFetchResponseErrorMessage';
import { useFirebase } from '../../../../../context';

interface Props {
  caseId: string;
  patientFullname: string;
  open: boolean;
  onClose: () => void;
}

export const DischargeConfirmationModal = ({
  caseId,
  patientFullname,
  open,
  onClose,
}: Props) => {
  const { auth } = useFirebase();

  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [dischargeOptions, setDischargeOptions] = useState<
    EbpCase['dischargeOptions']
  >({
    emails: {
      skipEmailToClientDischarged: false,
      skipEmailToClientFeedback: false,
    },
  });

  useEffect(() => {
    setErrorMessage(null);
    setSubmitting(false);
    setDischargeOptions({
      emails: {
        skipEmailToClientDischarged: false,
        skipEmailToClientFeedback: false,
      },
    });
  }, [caseId]);

  const dischargeCase = async () => {
    setSubmitting(true);
    setErrorMessage(null);
    try {
      const idTokenResult = await auth.currentUser?.getIdTokenResult(true);
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/cases/${caseId}/discharge`,
        {
          headers: {
            Authorization: `Bearer ${idTokenResult?.token}`,
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify(dischargeOptions),
        },
      );

      if (!response.ok) {
        const errorMsg = await getFetchResponseErrorMessage(response);
        console.error(errorMsg);
        setErrorMessage(errorMsg);
      }

      onClose();
    } catch (error) {
      console.error(error);
      setErrorMessage((error as Error).message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Modal open={open} onClose={onClose} center>
      <h3>Discharge confirmation</h3>
      <p>
        You are going to discharge the case for the member{' '}
        {
          <span className={styles.patient}>
            <b>{patientFullname}</b>
          </span>
        }
        .
      </p>
      <label>Send emails after discharge:</label>
      <div style={{ marginLeft: '12px' }}>
        <Checkbox
          checked={!dischargeOptions.emails.skipEmailToClientDischarged}
          text={ebpCaseEmails.ToLandgClientDischarged.name}
          value={''}
          onChange={(e) => {
            setDischargeOptions((prev) => ({
              ...prev,
              emails: {
                ...prev.emails,
                skipEmailToClientDischarged: !e.target.checked,
              },
            }));
          }}
        />
        <Checkbox
          checked={!dischargeOptions.emails.skipEmailToClientFeedback}
          text={ebpCaseEmails.ToLandgClientFeedback.name}
          value={''}
          onChange={(e) => {
            setDischargeOptions((prev) => ({
              ...prev,
              emails: {
                ...prev.emails,
                skipEmailToClientFeedback: !e.target.checked,
              },
            }));
          }}
        />
      </div>
      <br />
      Proceed?
      <br />
      <br />
      {errorMessage && <AlertError>{errorMessage}</AlertError>}
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button onClick={onClose} disabled={submitting}>
          Cancel
        </Button>

        <Button
          level="secondary"
          disabled={!caseId}
          submitting={submitting}
          onClick={async () => {
            await dischargeCase();
          }}
        >
          Confirm
        </Button>
      </div>
    </Modal>
  );
};
