import {
  MycawFollowUpFormData,
  MycawInitialFormData,
} from '@packages/core-shared';

const fieldRegEx = new RegExp(/{{.*}}/);

export const getCoercedFormTitle = (
  title: string,
  form: MycawFollowUpFormData | MycawInitialFormData,
) => {
  const fieldMatches = fieldRegEx.exec(title);
  let coercedTitle = title;
  if (fieldMatches?.length && fieldMatches.length > 0) {
    for (const m of fieldMatches) {
      if (m.includes('field:')) {
        const fieldRef = m.replace('{{field:', '').replace('}}', '');

        const fieldMetadata = Object.keys(form)
          .map((key) => form[key])
          .find((f) => f.ref === fieldRef);

        if (fieldMetadata) {
          coercedTitle = coercedTitle.replace(
            `{{field:${fieldRef}}}`,
            fieldMetadata.title,
          );
        }
      } else if (m.includes('hidden:')) {
        const hiddenFieldRef = m.replace('{{hidden:', '').replace('}}', '');

        const hiddenFieldKey = `hidden_${hiddenFieldRef}`;

        const hiddenFieldValue = (form[hiddenFieldKey] as any) ?? '';

        if (hiddenFieldValue) {
          coercedTitle = coercedTitle.replace(
            `{{hidden:${hiddenFieldRef}}}`,
            hiddenFieldValue,
          );
        }
      }
    }
  }
  return coercedTitle;
};
