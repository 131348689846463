import { EbpCaseEmail } from '@packages/core-shared';
import { AlertError, Button, Tooltip } from '@percihealth/react';
import { useMemo, useState } from 'react';
import { EmailsApiRepository } from '../../../api';
import styles from './PauboxStatus.module.scss';
import QuestionIcon from '../../icons/QuestionIcon';
import { useFirebase } from '../../../context';

interface Props {
  email: EbpCaseEmail;
  disabled: boolean;
}

export default function PauboxStatusCell({ email, disabled }: Props) {
  const { auth } = useFirebase();

  const [syncingPauboxStatus, setSyncingPauboxStatus] = useState(false);
  const [syncPauboxStatusError, setSyncPauboxStatusErrors] = useState<
    string | null
  >(null);
  const emailsApiRepository = useMemo(
    () => new EmailsApiRepository(auth),
    [auth],
  );

  const updatePauboxStatus = async () => {
    setSyncPauboxStatusErrors(null);
    setSyncingPauboxStatus(true);
    try {
      await emailsApiRepository.syncStatus(email.id);
    } catch (error: any) {
      console.error(error);
      setSyncPauboxStatusErrors(error.message);
    } finally {
      setSyncingPauboxStatus(false);
    }
  };

  return (
    <div className={styles.container}>
      <div>{email.status}</div>
      <div
        style={{
          display: 'flex',
          gap: '8px',
          alignItems: 'center',
          marginTop: '8px',
        }}
      >
        <Button
          size="small"
          level="secondary"
          disabled={disabled}
          submitting={syncingPauboxStatus}
          onClick={updatePauboxStatus}
        >
          Sync status
        </Button>
        <Tooltip
          trigger={
            <div>
              <QuestionIcon className={styles.question} />
            </div>
          }
        >
          <div>
            Paubox message status is tracked automatically once per day for 7
            days maximum, for a faster track please use manual synchronization
            by clicking the button.
          </div>
        </Tooltip>
      </div>

      {syncPauboxStatusError && (
        <AlertError>{syncPauboxStatusError}</AlertError>
      )}
    </div>
  );
}
