import { Professional } from '@packages/core-shared';
import { collection, orderBy, query } from 'firebase/firestore';
import { useMemo } from 'react';
import { useLoadLimitedFirestoreList } from '../../hooks/useLoadLimitedFirestoreList';
import { useFirebase } from '../../context';

export const useLoadProfessionals = () => {
  const { firestore } = useFirebase();

  const queryResult = useMemo(() => {
    const q = query(
      collection(firestore, 'professionals'),
      orderBy('fullname'),
    );
    return q;
  }, [firestore]);

  const professionalsLoader = useLoadLimitedFirestoreList<Professional>(
    queryResult,
    1000,
  );

  return professionalsLoader;
};
