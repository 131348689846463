import { useEffect, useMemo, useRef, useState } from 'react';
import { useFormik, FormikProvider, FormikHelpers } from 'formik';
import { useParams } from 'react-router-dom';
import debounce from 'lodash/debounce';
import styles from './CreateWorkFocusedPlanPage.module.scss';
import {
  EbpCase,
  IamCancerOptionsWithEmptyValue,
  cancerStatusToString,
  CareDocumentType,
  WorkFocusedPlanData,
  WorkFocusedPlanDataSchema,
  ReferralLandg,
  CareDocumentColumn,
  displayEbpDocumentType,
  MycawInitialForm,
  MycawInitialFormHelper,
  Pcp,
  Referral,
  ReferralType,
  getFirstnameAndInitialOfLastname,
  earliestDayTime,
  deepPartialOrNullable,
} from '@packages/core-shared';
import {
  AlertError,
  Button,
  Form,
  FormFieldset,
  FormikDatepicker,
  FormikDropdownSearch,
  FormikInputText,
  Spinner,
} from '@percihealth/react';
import { useGetFirestoreDocument } from '@packages/web-shared/hooks/useGetFirestoreDocument';
import { CareDocumentsApiRepository } from '@packages/web-shared/api/care-documents';
import { getDownloadURL, ref } from 'firebase/storage';
import DocumentColumns from '../components/DocumentColumns';
import OvercomeChallenges from '../components/OvercomeChallenges';
import { PdfGeneratedModal } from '../components/PdfGeneratedModal';
import { useFirebase } from '@packages/web-shared';

export default function CreateWorkFocusedPlanPage() {
  const params = useParams();
  const caseId = params?.caseId ?? null;

  if (!caseId) {
    return <div>Case ID (email) should be specified</div>;
  }

  const { storage, auth } = useFirebase();

  const [openPdfModal, setOpenPdfModal] = useState(false);
  useEffect(() => {
    setOpenPdfModal(false);
  }, [caseId]);

  const docsApiRepository = useMemo(
    () => new CareDocumentsApiRepository(auth),
    [auth],
  );

  const {
    record: ebpCase,
    loading: caseLoading,
    errorMessage: caseLoadingErrorMessage,
  } = useGetFirestoreDocument<EbpCase>('cases', caseId);

  const { record: mycawInitialForm } =
    useGetFirestoreDocument<MycawInitialForm>(
      'forms',
      ebpCase?.forms?.mycawInitialFormId,
    );

  const initialFormHelper = useMemo(
    () => new MycawInitialFormHelper(mycawInitialForm),
    [mycawInitialForm],
  );

  const migrateOldDataStructure = (doc: WorkFocusedPlanData | null) => {
    // Migration for old data structure
    if (doc) {
      doc.workImpact = doc.workImpact ?? defaultWorkImpacts;
      for (const wi of doc.workImpact) {
        if ((wi as any)?.title && !wi?.header) {
          wi.header = (wi as any).title;
        }
      }
      doc.planToOvercomeChallenges = doc.planToOvercomeChallenges ?? [];

      if (
        doc.patient &&
        doc.patient.work &&
        doc.patient.work.hideHopingToReturnToWorkIn === undefined
      ) {
        doc.patient.work.hideHopingToReturnToWorkIn = false;
      }
    }

    return doc;
  };

  const docLoader = useGetFirestoreDocument<WorkFocusedPlanData>(
    'documents',
    ebpCase?.documents?.workFocusedPlan?.id ?? null,
    migrateOldDataStructure,
  );

  const pcpLoader = useGetFirestoreDocument<Pcp>(
    'documents',
    ebpCase?.documents?.pcp?.id ?? null,
  );

  const docAutosaved = useGetFirestoreDocument<WorkFocusedPlanData>(
    `cases/${caseId}/documents-autosaved`,
    CareDocumentType.WorkFocusedPlan,
    migrateOldDataStructure,
  );

  const [autosaveError, setAutosaveError] = useState<string | null>(null);
  useEffect(() => {
    setAutosaveError(null);
  }, [caseId]);

  const autoSave = async (values: any) => {
    console.log('autosave');
    try {
      setAutosaveError(null);
      values.caseId = caseId;
      await docsApiRepository.autosaveWorkFocusedPlanForm(values);
    } catch (error: unknown) {
      console.error(error);
      setAutosaveError('Failed to autosave');
    }
  };
  const debouncedAutosave = useRef(debounce(autoSave, 2000));

  const [createResponseError, setCreateResponseError] = useState<string | null>(
    null,
  );

  const handleOnSubmit = async (
    values: WorkFocusedPlanData,
    actions: FormikHelpers<WorkFocusedPlanData>,
  ) => {
    try {
      actions.setSubmitting(true);
      setCreateResponseError(null);
      const { storageRef } =
        await docsApiRepository.createWorkFocusedPlan(values);

      const previewUrl = await getDownloadURL(ref(storage, storageRef));

      setOpenPdfModal(true);

      // Open the file in a new tab, so it is not automatically downloaded to the local storage
      window.open(previewUrl, '_blank');
    } catch (error: unknown) {
      let errorMsg = '';

      if (error instanceof Error) {
        errorMsg = error.message;
      } else {
        errorMsg = JSON.stringify(error);
      }

      console.error(errorMsg);
      setCreateResponseError(errorMsg);
    } finally {
      actions.setSubmitting(false);
    }
  };

  const { record: referral } = useGetFirestoreDocument<Referral>(
    'referrals',
    ebpCase?.referralId,
  );
  const gipCode = useMemo(() => {
    if (referral?.type !== ReferralType.landg) {
      return '';
    }

    return (referral as ReferralLandg)?.gip?.code ?? '';
  }, [referral]);

  const formikDependencies = [
    docLoader.record,
    docAutosaved?.record,
    pcpLoader.record,
    initialFormHelper,
    ebpCase,
    gipCode,
  ];

  const initialValues: WorkFocusedPlanData = useMemo(() => {
    let docData = docAutosaved.record ?? docLoader.record;
    if (docData) {
      // Deep clone
      try {
        // schema cast is required to have Date type instead of strings
        docData = deepPartialOrNullable(WorkFocusedPlanDataSchema).cast(
          JSON.parse(JSON.stringify(docData)),
        ) as WorkFocusedPlanData;
      } catch {
        // intentionally left blank
      }
    }

    const result = docData ?? {
      caseId,
      type: CareDocumentType.WorkFocusedPlan,
      patient: {
        fullName: ebpCase?.patient
          ? getFirstnameAndInitialOfLastname(ebpCase.patient)
          : '',
        dob:
          ebpCase?.patient?.dateOfBirth ??
          pcpLoader.record?.patient?.dob ??
          earliestDayTime(new Date()),
        iam: ebpCase?.patient?.cancerStatus
          ? cancerStatusToString(ebpCase?.patient?.cancerStatus)
          : IamCancerOptionsWithEmptyValue[0],
        work: {
          dateSignedOff: earliestDayTime(new Date()),
          hideHopingToReturnToWorkIn: false,
          hopingToReturnToWorkIn: '',
          jobTitle: initialFormHelper.getWorkRole() ?? '',
          workPattern: initialFormHelper.getWorkPattern() ?? '',
        },
      },
      gip: {
        code: gipCode,
      },
      workImpact: defaultWorkImpacts,
      planToOvercomeChallenges: [],
      dateOfConsultation: earliestDayTime(new Date()),
    };

    return result;
  }, [...formikDependencies]);

  const formik = useFormik({
    enableReinitialize: true,
    isInitialValid: WorkFocusedPlanDataSchema.isValidSync(initialValues),
    // Show previous PCP info if it was specified previously
    initialValues: initialValues,
    validationSchema: WorkFocusedPlanDataSchema,
    onSubmit: handleOnSubmit,
  });

  useEffect(() => {
    formik.resetForm();
  }, [...formikDependencies]);

  useEffect(() => {
    if (formik?.dirty) {
      debouncedAutosave.current(formik.values);
    }
  }, [formik?.values, formik?.dirty]);

  if (caseLoading || docLoader.loading) {
    return <Spinner />;
  }

  if (caseLoadingErrorMessage || !ebpCase) {
    return (
      <AlertError>{caseLoadingErrorMessage ?? 'Not found case'}</AlertError>
    );
  }

  if (docLoader.errorMessage) {
    return <AlertError>{docLoader.errorMessage}</AlertError>;
  }

  return (
    <FormikProvider value={formik}>
      <div>
        {autosaveError && <AlertError>{autosaveError}</AlertError>}
        <Form onSubmit={formik.handleSubmit}>
          <FormFieldset title="Patient's details">
            <FormikInputText
              id="patient.fullName"
              name="patient.fullName"
              required
              label="Patient full name"
              value={formik.values.patient.fullName}
            />
            <FormikDatepicker
              id="patient.dob"
              name="patient.dob"
              required
              label="Patient DOB"
              className="w-full"
              value={formik.values.patient.dob}
            />
            <FormikDropdownSearch
              name="patient.iam"
              label="Patient is:"
              required
              multiple={false}
              value={formik.values.patient.iam ?? ''}
              options={IamCancerOptionsWithEmptyValue.map((o) => ({
                value: o,
                name: o,
              }))}
            />
          </FormFieldset>
          <FormFieldset title="Patient work details">
            <FormikDatepicker
              id="patient.work.dateSignedOff"
              name="patient.work.dateSignedOff"
              required
              label="Date signed-off work"
              value={formik.values.patient.work.dateSignedOff}
            />
            <FormikInputText
              id="patient.work.jobTitle"
              name="patient.work.jobTitle"
              required
              label="Job title"
              value={formik.values.patient.work.jobTitle}
            />
            <FormikInputText
              id="patient.work.workPattern"
              name="patient.work.workPattern"
              required
              label="Work pattern"
              value={formik.values.patient.work.workPattern}
            />
            <FormikInputText
              id="patient.work.hopingToReturnToWorkIn"
              name="patient.work.hopingToReturnToWorkIn"
              required
              label="Hoping to return to work in"
              value={formik.values.patient.work.hopingToReturnToWorkIn}
              checked={!formik.values.patient.work.hideHopingToReturnToWorkIn}
              disabled={formik.values.patient.work.hideHopingToReturnToWorkIn}
              onCheckedChange={() => {
                const newHideHopingToReturnToWorkIn =
                  !formik.values.patient.work.hideHopingToReturnToWorkIn;
                if (newHideHopingToReturnToWorkIn) {
                  formik.setFieldValue(
                    'patient.work.hopingToReturnToWorkIn',
                    '',
                    true,
                  );
                }
                formik.setFieldValue(
                  'patient.work.hideHopingToReturnToWorkIn',
                  newHideHopingToReturnToWorkIn,
                  true,
                );
              }}
            />
            <FormikInputText
              id="gip.code"
              name="gip.code"
              required
              disabled={!!gipCode}
              label="Group income protection code"
              value={formik.values.gip.code}
            />
          </FormFieldset>
          <FormFieldset title="Manual">
            <FormikDatepicker
              id="dateOfConsultation"
              name="dateOfConsultation"
              required
              label="Date of consultation"
              value={formik.values.dateOfConsultation}
            />
          </FormFieldset>
          <FormFieldset title="How cancer is impacting your work">
            <DocumentColumns
              columns={formik.values.workImpact}
              onChange={(arr) => {
                formik.setFieldValue('workImpact', arr);
              }}
            />
          </FormFieldset>

          <FormFieldset title="Plan to help overcome challenges to getting back to work">
            <OvercomeChallenges
              values={formik.values.planToOvercomeChallenges}
              onChange={(arr) => {
                formik.setFieldValue('planToOvercomeChallenges', arr);
              }}
            />
          </FormFieldset>
          <hr />
          <div className={styles.full}>
            <Button
              type="submit"
              className={styles.submit}
              fullWidth
              disabled={!formik.isValid}
              submitting={formik.isSubmitting}
            >
              Create {displayEbpDocumentType(CareDocumentType.WorkFocusedPlan)}
            </Button>
          </div>
          {createResponseError && (
            <AlertError>{createResponseError}</AlertError>
          )}
        </Form>
      </div>
      <PdfGeneratedModal
        caseId={caseId}
        open={openPdfModal}
        onClose={() => setOpenPdfModal(false)}
      />
    </FormikProvider>
  );
}

const defaultWorkImpacts: CareDocumentColumn[] = [
  {
    header: 'Cancer and treatment',
    values: [
      'Pre existing conditions',
      'Prognosis and symptoms',
      'Treatment specifics',
      'Physical, psychological, social',
    ],
  },
  {
    header: 'Person related',
    values: [
      'Perceived impact of cancer on work',
      'Meaning of work',
      'Attitudes towards work',
      'Change in priorities',
      'Expectation of recovery and work ability',
      'Coping and wellbeing at work',
      'Socio demographic',
    ],
  },
  {
    header: 'Healthcare services and support networks',
    values: [
      'Healthcare support systems',
      'Hospital appointments',
      'CNS',
      'Local support',
      'Charity',
      'Allied healthcare professionals',
      'Insurance financial support',
      'Legal support',
      'Community support',
    ],
  },
  {
    header: 'Job demands and workplace support',
    values: [
      'Psychological demands',
      'Physical demands',
      'Available adjustments',
      'Supportive line manager',
      'Job security',
    ],
  },
];
