import { EmailsApiRepository } from '../../../api';
import { useMemo, useState } from 'react';
import { AlertError, Button } from '@percihealth/react';
import { useFirebase } from '../../../context';

interface Props {
  caseId: string;
  documentId: string;
  template: string;
  disabled: boolean;
}

export default function PauboxResendCell({
  caseId,
  documentId,
  template,
  disabled,
}: Props) {
  const { auth } = useFirebase();

  const emailsApiRepository = useMemo(
    () => new EmailsApiRepository(auth),
    [auth],
  );
  const [resendingEmail, setResendingEmail] = useState(false);
  const [resendingError, setResendingError] = useState<string | null>(null);

  const resend = async () => {
    try {
      setResendingEmail(true);
      setResendingError(null);
      await emailsApiRepository.resend({ caseId, documentId, template });
    } catch (err) {
      console.error(err);
      setResendingError(
        err instanceof Error ? err.message : JSON.stringify(err),
      );
    } finally {
      setResendingEmail(false);
    }
  };

  return (
    <div>
      <Button onClick={resend} disabled={disabled} submitting={resendingEmail}>
        Resend
      </Button>
      {resendingError && <AlertError>{resendingError}</AlertError>}
    </div>
  );
}
