import { query, collection, orderBy, where } from 'firebase/firestore';
import styles from './ConsentShareDataToInsurerHistory.module.css';
import {
  EbpCaseChange,
  dateTimeToLocalDateTimeString,
} from '@packages/core-shared';
import { AlertError, Spinner } from '@percihealth/react';
import { useSubscribeFirestoreList } from '../../../hooks/useSubscribeFirestoreList';
import { useMemo } from 'react';
import { useFirebase } from '../../../context';

export default function ConsentShareDataToInsurerHistory({
  caseId,
}: {
  caseId: string;
}) {
  const { firestore } = useFirebase();

  const queryResult = useMemo(() => {
    const q = query(
      collection(firestore, `cases/${caseId}/changes`),
      where('type', '==', 'consentShareDataToInsurer'),
      orderBy('createdAt', 'desc'),
    );
    return q;
  }, [firestore, caseId]);

  const changesLoader = useSubscribeFirestoreList<EbpCaseChange>(queryResult);

  return (
    <div className={styles.container}>
      {changesLoader.loading && <Spinner />}
      {changesLoader.errorMessage && (
        <AlertError> {changesLoader.errorMessage}</AlertError>
      )}
      {changesLoader.records.map((change) => (
        <div
          key={dateTimeToLocalDateTimeString(change.createdAt)}
        >{`${dateTimeToLocalDateTimeString(change.createdAt)}: ${
          change.author
        } marked as ${change.new ? 'checked' : 'unchecked'}`}</div>
      ))}
    </div>
  );
}
