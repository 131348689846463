import { useMemo } from 'react';
import { useLoadPatientAppointments } from './useLoadPatientAppointments';
import { Appointment } from '@packages/core-shared';

export const useLoadPatientAppointmentsByService = ({
  patientEmail,
}: {
  patientEmail?: string;
}) => {
  const appointmentsLoader = useLoadPatientAppointments({
    patientEmail,
  });

  const records = useMemo(() => {
    // appointments are ordered by date DESC
    const map = appointmentsLoader.records.reduce(
      (acc, app) => {
        // take the ealiest one
        for (const serviceId of app.services ?? []) {
          if (serviceId) {
            if (!acc[serviceId.toString()]) {
              acc[serviceId.toString()] = [];
            }
            acc[serviceId.toString()].push(app);
          }
        }
        return acc;
      },
      {} as Record<string, Appointment[]>,
    );

    return map;
  }, [appointmentsLoader.records]);

  return {
    records,
    loading: appointmentsLoader.loading,
    errorMessage: appointmentsLoader.errorMessage,
  };
};
